import * as actionTypes from '../actions/types.actions'

const initialState = {
    positionsLoaded: false,
    positions: [],
    projectTypesLoaded: false,
    projectTypes: [],
    projectStagesLoaded: false,
    projectStages: [],
    contractTypes: [],
    contractTypesLoaded: false,
    projects: [],
    projectsLoaded: false,
}

const typesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EMPLOYEES_POSITIONS_SUCCESS: {
            return {
                ...state,
                positions: action.positions.map(position => ({value: position._id, label: position.name})),
                positionsLoaded: true,
            }
        }
        case actionTypes.LOAD_PROJECT_TYPES_SUCCESS: {
            return {
                ...state,
                projectTypes: action.types.map(type => ({value: type._id, label: type.name})),
                projectTypesLoaded: true,
            }
        }
        case actionTypes.LOAD_PROJECT_STAGES_SUCCESS: {
            return {
                ...state,
                projectStages: action.stages.map(stage => ({value: stage._id, label: stage.name})),
                projectStagesLoaded: true,
            }
        }
        case actionTypes.LOAD_CONTRACT_TYPES_SUCCESS: {
            return {
                ...state,
                contractTypes: action.contracttypes.map(contracttypes => ({
                    value: contracttypes._id,
                    label: contracttypes.name,
                })),
                contractTypesLoaded: true,
            }
        }
        case actionTypes.LOAD_PROJECT_LIST_SUCCESS: {
            return {
                ...state,
                projects: action.projects.length
                    ? action.projects.map(project => ({
                          value: project.id,
                          label: project.name,
                      }))
                    : [{value: 'no projects', label: 'no projects'}],
                projectsLoaded: true,
            }
        }
        default:
            return state
    }
}

export default typesReducer
