import {store} from '../../App'
import axios from 'axios'
import {handleError} from '../../helpers'
import {DELETE_EMPLOYEE_FAILURE} from './employees.actions'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_USER_LEAVE = 'LOAD_USER_LEAVE'
export const LOAD_USER_LEAVE_SUCCESS = 'LOAD_USER_LEAVE_SUCCESS'
export const LOAD_USER_LEAVE_FAILURE = 'LOAD_USER_LEAVE_FAILURE'

export const LOAD_ALL_LEAVE = 'LOAD_ALL_LEAVE'
export const LOAD_ALL_LEAVE_SUCCESS = 'LOAD_ALL_LEAVE_SUCCESS'
export const LOAD_ALL_LEAVE_FAILURE = 'LOAD_ALL_LEAVE_FAILURE'

export const ACCEPT_LEAVE = 'ACCEPT_LEAVE'
export const ACCEPT_LEAVE_SUCCESS = 'ACCEPT_LEAVE_SUCCESS'
export const ACCEPT_LEAVE_FAILURE = 'ACCEPT_LEAVE_FAILURE'

export const ADD_LEAVE = 'ADD_LEAVE'
export const ADD_LEAVE_SUCCESS = 'ADD_LEAVE_SUCCESS'
export const ADD_LEAVE_FAILURE = 'ADD_LEAVE_FAILURE'

export const DELETE_LEAVE = 'DELETE_LEAVE'
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS'
export const DELETE_LEAVE_FAILURE = 'DELETE_LEAVE_FAILURE'

export const EDIT_LEAVE = 'EDIT_LEAVE'
export const EDIT_LEAVE_SUCCESS = 'EDIT_LEAVE_SUCCESS'
export const EDIT_LEAVE_FAILURE = 'EDIT_LEAVE_FAILURE'

export const loadUserLeave = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const body = {
            query: ` query {
                        vacations (where: {user: "${store.getState().auth.user.id}"}) {
                        id
                        start
                        end
                        accepted
                        }
                        }
            `,
        }
        dispatch({type: LOAD_USER_LEAVE})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadUserLeaveSuccess(response.data.data.vacations))
            })
            .catch(error => dispatch(loadUserLeaveFailure(error.response.data.data)))
    }
}

export const loadUserLeaveSuccess = leave => {
    return {
        type: LOAD_USER_LEAVE_SUCCESS,
        leave,
    }
}

export const loadUserLeaveFailure = error => {
    handleError(error)
    return {
        type: LOAD_USER_LEAVE_FAILURE,
        error: error,
    }
}

export const acceptLeave = leaveId => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const body = {
            query: ` mutation {
            updateVacation (input:
            {
            where: {id: "${leaveId}"},
            data:
            {
            accepted: true
            }
            })
            {
            vacation{
            id
            }
            }
            }
            `,
        }
        dispatch({type: ACCEPT_LEAVE})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(() => {
                dispatch(acceptLeaveSuccess(leaveId))
            })
            .catch(error => dispatch(acceptLeaveFailure(error.response.data.data)))
    }
}

export const acceptLeaveSuccess = leaveId => {
    return {
        type: ACCEPT_LEAVE_SUCCESS,
        leaveId,
    }
}

export const acceptLeaveFailure = error => {
    handleError(error)
    return {
        type: ACCEPT_LEAVE_FAILURE,
        error: error,
    }
}

export const loadAllLeave = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const body = {
            query: ` query {
                        vacations  (where: {start_gte: "${new Date().getFullYear().toString()}-01", user: {company: {id: "${store.getState().auth.user.companyId}"}}}) {
                        id
                        start
                        end
                        accepted
                        user {username}
                        }
                        }
            `,
        }
        dispatch({type: LOAD_ALL_LEAVE})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadAllLeaveSuccess(response.data.data.vacations))
            })
            .catch(error => dispatch(loadAllLeaveFailure(error.response.data.data)))
    }
}

export const loadAllLeaveSuccess = leave => {
    return {
        type: LOAD_ALL_LEAVE_SUCCESS,
        leave,
    }
}

export const loadAllLeaveFailure = error => {
    handleError(error)
    return {
        type: LOAD_ALL_LEAVE_FAILURE,
        error: error,
    }
}

export const addLeave = (start, end) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: ADD_LEAVE})
        const body = {
            query: ` mutation {
                createVacation (input:
                {
                data:
                {
                start: "${new Date(start).toISOString().slice(0, 10)}",
                end: "${new Date(end).toISOString().slice(0, 10)}",
                user: "${store.getState().auth.user.id}"
                accepted: false
                }
                })
                {
                vacation{
                id
                start
                end
                accepted
                }
                }
                }
            `,
        }
        dispatch({type: ADD_LEAVE})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(addLeaveSuccess(response.data.data.createVacation.vacation))
            })
            .catch(error => dispatch(addLeaveFailure(error.response.data.data)))
    }
}

export const addLeaveSuccess = leave => {
    return {
        type: ADD_LEAVE_SUCCESS,
        leave,
    }
}

export const addLeaveFailure = error => {
    handleError(error)
    return {
        type: ADD_LEAVE_FAILURE,
        error: error,
    }
}

export const deleteLeave = leaveId => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_LEAVE})
        axios
            .delete(`${baseUrl}/vacations/${leaveId}`, config)
            .then(() => {
                dispatch(deleteLeaveSuccess(leaveId))
            })
            .catch(error => dispatch(deleteLeaveFailure(error.response.data.data)))
    }
}

export const deleteLeaveSuccess = leaveId => {
    return {
        type: DELETE_LEAVE_SUCCESS,
        leaveId,
    }
}

export const deleteLeaveFailure = error => {
    handleError(error)
    return {
        type: DELETE_EMPLOYEE_FAILURE,
        error: error,
    }
}
