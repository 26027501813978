import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styles from './tabs.module.scss'

class Tabs extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            active: 0,
        }
    }

    select = i => {
        let _this = this
        return function() {
            _this.setState({
                active: i,
            })
        }
    }

    renderTabs = () => {
        return React.Children.map(this.props.children, (item, i) => {
            if (i % 2 === 0) {
                return (
                    <div
                        onClick={this.select(i)}
                        className={`${this.state.active === i ? styles.active : ''} ${styles.tab}`}>
                        {item}
                    </div>
                )
            }
        })
    }

    renderContent() {
        return React.Children.map(this.props.children, (item, i) => {
            if (i - 1 === this.state.active) {
                return <div className="content">{item}</div>
            }
            return
        })
    }

    render() {
        return (
            <div>
                <div className={styles.tabs}> {this.renderTabs()}</div>
                {this.renderContent()}
            </div>
        )
    }
}

export default Tabs
