import React from 'react'
import {
    loadProjectDetails,
    loadProjects,
    deleteCost,
    addCost,
    archiveCost,
    deleteProject,
    saveProjectChanges,
} from '../../store/actions/projects.actions'
import {loadPositions} from '../../store/actions/types.actions'
import {loadEmployees} from '../../store/actions/employees.actions'
import {connect} from 'react-redux'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import ProjectDetailsHeader from '../../components/ProjectDetails/ProjectDetailsHeader/ProjectDetailsHeader'
import ProjectDetailsCosts from '../../components/ProjectDetails/ProjectDetailsCosts/ProjectDetailsCosts'
import {showModal} from '../../store/actions/modals.actions'
import ProjectDetailsNewCost from '../../components/ProjectDetails/ProjectDetailsNewCost/ProjectDetailsNewCost'
import Spinner from '../../components/Spinner/Spinner'

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            projectId: this.props.match.params.id,
            projectIndex: -1,
            projectLoaded: false,
            costToDelete: null,
        }
    }

    componentDidMount() {
        if (!this.props.projectsLoaded) {
            this.props.loadProjects()
        } else {
            const index = this.props.projects.findIndex(project => project.id === this.props.match.params.id)
            if (index !== -1 && !this.props.projects[index].detailsLoaded) {
                this.props.loadProjectDetails(this.state.projectId)
            } else {
                this.setState({
                    ...this.state,
                    projectLoaded: true,
                    projectIndex: index,
                })
            }
        }
        if (!this.props.positionsLoaded) {
            this.props.loadPositions()
        }
        if (!this.props.employeesLoaded) {
            this.props.loadEmployees()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectsLoaded !== prevProps.projectsLoaded) {
            this.setState({
                ...this.state,
                projectIndex: this.props.projects.findIndex(
                    project => project.id === parseInt(this.props.match.params.id),
                ),
            })
            this.props.loadProjectDetails(this.state.projectId)
        } else {
            const index = this.props.projects.findIndex(project => project.id === this.props.match.params.id)
            if (
                index !== -1 &&
                this.props.projectsLoaded &&
                prevProps.projects[index].detailsLoaded !== this.props.projects[index].detailsLoaded
            ) {
                this.setState({
                    ...this.state,
                    projectLoaded: true,
                    projectIndex: index,
                })
            }
        }
    }

    onProjectEdit = project => {
        this.props.saveProjectChanges(
            {...project, costs: this.props.projects[this.state.projectIndex].costs},
            this.state.projectId,
        )
    }

    confirmCostDelete = costId => {

        this.props.deleteCost(this.state.projectIndex, costId)
    }

    onCostDelete = costId => {
        this.props.showModal(
            {
                open: true,
                title: '',
                message: 'Are You sure You want to delete this employee?',
                confirm: this.confirmCostDelete(costId),
            },
            'confirm',
        )
    }

    onAddCost = cost => {
        const oldCostIndex = this.props.projects[this.state.projectIndex].costs.findIndex(
            project => project.employee === cost.employee,
        )
        if (oldCostIndex !== -1) {
            this.props.archiveCost(
                this.state.projectIndex,
                oldCostIndex,
                this.props.projects[this.state.projectIndex].costs[oldCostIndex].id,
            )
        }

        this.props.addCost(this.state.projectIndex, this.state.projectId, cost)
    }

    confirmProjectDelete = () => {
        this.props.deleteProject(this.state.projectIndex, this.state.projectId)
    }

    onProjectDelete = () => {
        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this project?',
                confirm: this.confirmProjectDelete,
            },
            'confirm',
        )
    }

    render() {
        const project = this.props.projects[this.state.projectIndex]
        return (
            <div className="page-margin my-4">
                {!this.props.projectLoaded && <Spinner />}
                {this.props.projectsLoaded &&
                    this.props.projects.findIndex(project => project.id === this.props.match.params.id) === -1 && (
                        <h4 className="mt-7 align-c">Nie ma takiego projektu :(</h4>
                    )}
                {this.state.projectLoaded && this.props.projectsLoaded && (
                    <div>
                        <h1 className="mb-5">Projects</h1>
                        <div className="d-flex a-center j-between">
                            <TwoPartTitle firstPart="Project" secondPart={project.name}></TwoPartTitle>
                            <button className="btn btn-pink btn-rectangle" onClick={() => this.onProjectDelete()}>
                                Delete project
                            </button>
                        </div>
                        <ProjectDetailsHeader
                            project={{
                                name: project.name,
                                projectType: project.projectType,
                                budget: project.budget,
                                projectStage: project.projectStage,
                                archived: project.archived,
                            }}
                            onSave={this.onProjectEdit}></ProjectDetailsHeader>
                        {this.props.positionsLoaded && this.props.employeesLoaded && (
                            <ProjectDetailsCosts
                                positions={this.props.positions}
                                employees={this.props.employees}
                                data={project.costs}
                                delete={this.onCostDelete}></ProjectDetailsCosts>
                        )}
                        {this.props.positionsLoaded && this.props.employeesLoaded && (
                            <ProjectDetailsNewCost
                                employees={this.props.employees}
                                positions={this.props.positions}
                                add={this.onAddCost}></ProjectDetailsNewCost>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        projectsLoaded: state.projects.projectsLoaded,
        positionsLoaded: state.types.positionsLoaded,
        positions: state.types.positions,
        employees: state.employees.employeeNames,
        employeesLoaded: state.employees.employeesLoaded,
        projectLoaded: state.projects.projectLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProjects: () => dispatch(loadProjects()),
        loadProjectDetails: id => dispatch(loadProjectDetails(id)),
        loadPositions: () => dispatch(loadPositions()),
        loadEmployees: () => dispatch(loadEmployees()),
        deleteCost: (projectIndex, costId) => dispatch(deleteCost(projectIndex, costId)),
        addCost: (projectIndex, projectId, cost) => dispatch(addCost(projectIndex, projectId, cost)),
        deleteProject: (projectIndex, projectId) => dispatch(deleteProject(projectIndex, projectId)),
        saveProjectChanges: (project, projectId) => dispatch(saveProjectChanges(project, projectId)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        archiveCost: (projectIndex, costIndex, costId) => dispatch(archiveCost(projectIndex, costIndex, costId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
