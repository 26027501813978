export const mapStrapiCost = cost => {
    return {
        id: cost.id,
        employee: cost.user ? cost.user.id : cost.user,
        position: cost.employeeLevel.id ? cost.employeeLevel.id : cost.employeeLevel,
        internalRate: cost.internal,
        externalRate: cost.external,
        archived: cost.archived,
    }
}

export const mapStrapiProject = project => {
    return {
        ...project,
        projectStage: project.projectStage ? project.projectStage.id : '',
        projectType: project.projectType ? project.projectType.id : '',
        costs: project.costs ? project.costs.map(employee => mapStrapiCost(employee)) : [],
    }
}

export const mapAppCost = (projectId, cost) => {
    return {
        internal: cost.internalRate,
        external: cost.externalRate,
        project: projectId,
        employeeLevel: cost.position,
        user: cost.employee,
        archves: false,
    }
}

export const mapAppProject = project => {
    return {
        name: project.name,
        budget: project.budget,
        archived: project.archived,
        projectStage: project.projectStage,
        projectType: project.projectType,
    }
}

export const mapStrapiUser = user => {
    return {
        id: user.id,
        username: user.username,
        contractType: user.contractType.id,
        additionalCosts: user.additionalCosts,
        tax: user.tax,
        vacations: user.vacations,
    }
}
