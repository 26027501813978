/* eslint-disable react/prop-types */
import React from 'react'
import {clone} from 'ramda'
import NumberInput from '../../../ui/NumberInput/NumberInput'
import SVG from 'react-inlinesvg'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import DataGrid from '../../../ui/DataGrid/DataGrid'

class ProjectDetailsCosts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            costs: clone(props.data).map(cost => ({
                ...cost,
                position: this.props.positions.find(opt => opt.value === cost.position)
                    ? this.props.positions.find(opt => opt.value === cost.position).label
                    : '',
                employee: this.props.employees.find(opt => opt.value === cost.employee)
                    ? this.props.employees.find(opt => opt.value === cost.employee).label
                    : '',
            })),
        }

        this.handleDelete = this.handleDelete.bind(this)
    }

    columns = [
        {
            dataField: 'employee',
            text: 'Employee',
            editable: false,
        },
        {
            dataField: 'position',
            text: 'Position',
            editable: false,
            editorRenderer: (editorProps, value) => (
                <CustomSelect
                    {...editorProps}
                    selected={this.props.positions.find(opt => opt.label === value)}
                    options={this.props.positions}></CustomSelect>
            ),
        },
        {
            dataField: 'internalRate',
            text: 'Internal Rate',
            editable: false,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'externalRate',
            text: 'External Rate',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },

        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                if (!row.archived) {
                    return (
                        <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleDelete(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                        </button>
                    )
                }
            },
        },
    ]

    componentDidUpdate(prevProps) {
        if (prevProps.data.length !== this.props.data.length) {
            this.setState({
                costs: clone(this.props.data).map(cost => ({
                    ...cost,
                    position: this.props.positions.find(opt => opt.value === cost.position)
                        ? this.props.positions.find(opt => opt.value === cost.position).label
                        : '',
                    employee: this.props.employees.find(opt => opt.value === cost.employee)
                        ? this.props.employees.find(opt => opt.value === cost.employee).label
                        : '',
                })),
            })
        } else if (prevProps.data !== this.props.data) {
            let newData = clone(this.state.costs)
            this.props.data.forEach((element, id) => {
                if (element && element.edited !== this.state.costs[id].edited) {
                    newData[id].edited = element.edited

                    this.setState({
                        costs: newData,
                    })
                }
            })
        }
    }

    handleDelete(cost) {
        this.props.delete(cost.id)
    }

    rowClasses = row => {
        let classes = null
        if (row.archived) {
            classes = 'archived'
        }

        return classes
    }

    render() {
        return (
            <div>
                <h2>Costs</h2>
                <DataGrid columns={this.columns} data={this.state.costs} rowClasses={this.rowClasses}></DataGrid>
            </div>
        )
    }
}

export default ProjectDetailsCosts
