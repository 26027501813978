import React from 'react'
import HistoryGrid from '../../components/HistoryGrid/HistoryGrid'

const history = () => {
    return (
        <div className="page-margin my-4">
            <h1 className="mb-5">History</h1>
            <HistoryGrid></HistoryGrid>
        </div>
    )
}

export default history
