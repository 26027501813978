import Select from 'react-dropdown-select'
import React, {Component} from 'react'
import './customSelect.scss'

class CustomSelect extends Component {
    constructor(props) {
        super(props)
        let selected = {value: '', label: ''}
        if (this.props.selected) {
            selected = this.props.selected
        }
        this.state = {selected: selected}
        this.selectRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.onUpdate) {
            this.selectRef.current.dropDown()
        }
    }

    updateSelect = selected => {
        this.setState({selected})
    }

    handleChange = values => {
        this.setState({
            selected: values[0],
        })
        if (this.props.onUpdate) {
            const onUpdate = this.props.onUpdate
            onUpdate(values[0].label)
        }
        if (this.props.change) {
            this.props.change(values[0].value)
        }
    }

    render() {
        return (
            <div>
                <Select
                    ref={this.selectRef}
                    values={[this.state.selected]}
                    options={this.props.options}
                    searchable={!!this.props.searchable}
                    labelField="label"
                    onChange={values => this.handleChange(values)}
                />
            </div>
        )
    }
}

export default CustomSelect
