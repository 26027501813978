import React, {Component} from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import NumberInput from '../../../ui/NumberInput/NumberInput'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import TwoPartTitle from '../../../ui/TwoPartTitle/TwoPartTitle'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import './Monthgrid.scss'
import '../../../scss/internal/forms.scss'
import {loadHours, editHour, addHour, deleteHour} from '../../../store/actions/timesheet.actions'
import {loadProjectList} from '../../../store/actions/types.actions'
import {connect} from 'react-redux'
import NewHour from '../NewHour/NewHour'
import SVG from 'react-inlinesvg'
import {clone} from 'ramda'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import Spinner from '../../Spinner/Spinner'
import {sortString} from '../../../helpers/sorter/sorter'

class MonthGrid extends Component {
    updateHandler = row => {
        this.props.editHour({
            ...row,
            project: this.props.projects.find(project => project.label === row.project)
                ? this.props.projects.find(project => project.label === row.project).value
                : '',
        })
    }

    add = hour => {
        this.props.addHour(hour)
    }

    handleDelete = hour => {
        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this record?',
                confirm: () => this.props.deleteHour(hour.id),
            },
            'confirm',
        )
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    sortData() {
        this.props.projects.sort(sortString('label'))
    }

    columns = [
        {
            dataField: 'project',
            text: 'Project',
            sort: true,
            editorRenderer: (editorProps, value) => {
                const selected = this.props.projects.find(opt => opt.label === value)

                return (
                    <CustomSelect
                        {...editorProps}
                        selected={selected ? selected : {value: '', label: ''}}
                        options={this.props.projects}></CustomSelect>
                )
            },
        },
        {
            dataField: 'date',
            sort: true,
            text: 'Date',
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
            editorRenderer: (editorProps, value) => (
                <CustomDatePicker
                    isOpen={true}
                    minDate={this.props.month.clone().toDate()}
                    maxDate={this.props.month
                        .clone()
                        .add(1, 'M')
                        .toDate()}
                    {...editorProps}
                    value={value}
                />
            ),
        },
        {
            dataField: 'task',
            text: 'Task',
        },
        {
            dataField: 'description',
            text: 'Description',
        },
        {
            dataField: 'value',
            text: 'Hours',
            editorRenderer: (editorProps, value) => (
                <NumberInput className="formControl" onUpdate={editorProps.onUpdate} value={value} type="hour"></NumberInput>
            ),
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleDelete(row)}>
                        <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                    </button>
                )
            },
        },
    ]

    render() {
        return (
            <div className="hourGrid">
                {this.props.hoursChanged === false ? this.showModal() : this.closeModal()}
                {this.props.projectsLoaded ? (
                    <div>
                        {this.sortData()}
                        <TwoPartTitle
                            firstPart={this.props.month.format('MMMM')}
                            secondPart={
                                this.props.hours.map(hour => hour.value).reduce((a, b) => a + b, 0) + 'h'
                            }></TwoPartTitle>
                        <NewHour
                            projects={this.props.projects}
                            month={this.props.month}
                            dateEditable={true}
                            lastProject={
                                this.props.hours.length > 0
                                    ? this.props.hours[this.props.hours.length - 1].project
                                    : null
                            }
                            addHour={this.add}></NewHour>
                        {this.props.hours.length > 0 && (
                            <>
                                <DataGrid
                                    columns={this.columns}
                                    data={clone(this.props.hours)}
                                    updated={this.updateHandler}
                                    rowClasses="form-control-table"
                                ></DataGrid>
                                <hr></hr>
                            </>
                        )}
                    </div>
                ) : (
                    <Spinner />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hoursLoaded: state.timesheet.hoursLoaded,
    projects: state.types.projects,
    projectsLoaded: state.types.projectsLoaded,
    hoursChanged: state.timesheet.hoursChanged,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: (month, year) => dispatch(loadHours(month, year)),
        loadProjectList: () => dispatch(loadProjectList()),
        editHour: hour => dispatch(editHour(hour)),
        addHour: hour => dispatch(addHour(hour)),
        deleteHour: hourId => dispatch(deleteHour(hourId)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthGrid)
