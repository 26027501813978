import React from 'react'
import {resetPassword} from '../../store/actions/authentication.actions'
import {connect} from 'react-redux'
import SVG from 'react-inlinesvg'
import * as qs from 'query-string'

class PasswordReset extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            password: '',
            passwordConfirmation: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.resetPassword(
            this.state.password,
            this.state.passwordConfirmation,
            qs.parse(this.props.location.search).code,
        )
    }

    render() {
        const {password, passwordConfirmation} = this.state
        return (
            <div className="form-container">
                <div className="form">
                    <div className="logo">
                        {this.props.loading ? (
                            <SVG src="/assets/spinner.svg" />
                        ) : (
                            <img src="/assets/logo_timesheet.png" alt="Logo" />
                        )}
                    </div>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="labeled-input mb-4">
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className="labeled-input mb-4">
                            <input
                                type="password"
                                className="form-control"
                                name="passwordConfirmation"
                                value={passwordConfirmation}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="password">Password Confirmation</label>
                        </div>

                        <div className="btn_container">
                            <button className="btn btn-pink py-2 mt-4'">
                                <SVG className="icon" src="/assets/icons/arrow-right.svg" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.auth.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (password, passwordConfirmation, code) =>
            dispatch(resetPassword(password, passwordConfirmation, code)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
