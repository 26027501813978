import React from 'react'
import {clone} from 'ramda'
import NumberInput from '../../ui/NumberInput/NumberInput'
import SVG from 'react-inlinesvg'
import CustomSelect from '../../ui/CustomSelect/CustomSelect'
import DataGrid from '../../ui/DataGrid/DataGrid'

const blockedSelect = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
]

class EmployeesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            employees: clone(props.data).map(employee => ({
                ...employee,
                contractType: this.props.contractTypes.find(opt => opt.value === employee.contractType)
                    ? this.props.contractTypes.find(opt => opt.value === employee.contractType).label
                    : '',
                blocked: employee.blocked? 'Yes':'No',
            })),
            employeesAscending: true,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.length !== this.props.data.length) {
            this.setState({
                employees: clone(this.props.data).map(employee => ({
                    ...employee,
                    contractType: this.props.contractTypes.find(opt => opt.value === employee.contractType)
                        ? this.props.contractTypes.find(opt => opt.value === employee.contractType).label
                        : '',
                        blocked: employee.blocked? 'Yes':'No',    
                })),
            })
        } else if (prevProps.data !== this.props.data) {
            let newData = clone(this.state.employees)
            this.props.data.forEach((element, id) => {
                if (element && element.edited !== this.state.employees[id].edited) {
                    newData[id].edited = element.edited
                    this.setState({
                        employees: newData,
                    })
                }
            })
        }
    }

    columns = [
        {
            dataField: 'username',
            text: 'Name',
            editable: false,
            sort: true,
        },
        {
            dataField: 'contractType',
            text: 'Contract type',
            editable: true,
            editorRenderer: (editorProps, value) => (
                <CustomSelect
                    {...editorProps}
                    selected={this.props.contractTypes.find(opt => opt.label === value)}
                    options={this.props.contractTypes}></CustomSelect>
            ),
        },
        {
            dataField: 'tax',
            text: 'Tax',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'additionalCosts',
            text: 'Additional cost',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'vacationDays',
            text: 'Vacation days',
            editable: true,
            editorRenderer: (editorProps, value) => <NumberInput onUpdate={editorProps.onUpdate} value={value} />,
        },
        {
            dataField: 'blocked',
            text: 'Blocked',
            editable: true,
            editorRenderer: (editorProps, value) => {
                return (
                    <>
                    <CustomSelect
                        {...editorProps}
                        selected={blockedSelect.find(opt => opt.label === value)}
                        options={blockedSelect}
                        onUpdate={editorProps.onUpdate}>
                    </CustomSelect>
                    </>
                )
            },
        },
        {
            dataField: 'save',
            isDummyField: true,
            text: 'Save',
            editable: false,
            formatter: (cellContent, row) => {
                if (row.edited) {
                    return (
                        <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleSave(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                        </button>
                    )
                }
            },
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleDelete(row)}>
                        <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                    </button>
                )
            },
        },
    ]

    handleDelete = employee => {
        this.props.delete(employee.id)
    }

    handleSave = employee => {
        const newEmployee = {
            ...clone(employee),
            contractType: this.props.contractTypes.find(opt => opt.label === employee.contractType).value,
        }

        this.props.save(newEmployee)
    }

    handleUpdate = employee => {
        this.props.update(employee.id)
    }

    render() {
        return <DataGrid columns={this.columns} data={this.state.employees} updated={this.handleUpdate}></DataGrid>
    }
}

export default EmployeesList
