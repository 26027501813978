import {history} from '../../helpers'
import axios from 'axios'
import {toast} from 'react-toastify'
import {handleError} from '../../helpers'

const baseUrl = process.env.REACT_APP_API_URL

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

export const PASSWORD_RECOVER = 'PASSWORD_RECOVER'
export const PASSWORD_RECOVER_SUCCESS = 'PASSWORD_RECOVER_SUCCESS'
export const PASSWORD_RECOVER_FAILURE = 'PASSWORD_RECOVER_FAILURE'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

export const login = (login, password) => {
    const credentials = {
        identifier: login,
        password: password,
    }
    return dispatch => {
        dispatch({type: 'LOGIN'})
        axios
            .post(`${baseUrl}/auth/local`, credentials)
            .then(response => {
                const user = {
                    token: response.data.jwt,
                    id: response.data.user.id,
                    username: response.data.user.username,
                    role: response.data.user.role.name,
                    leaveDays: response.data.user.vacationDays,
                    companyName: response.data.user.company.name,
                    companyId: response.data.user.company.id,
                    companyUrl: baseUrl + response.data.user.company.logo[0].url,
                }
                dispatch(loginSuccess(user))
            })
            .catch(error => dispatch(loginFailure(error.response.data.message)))
    }
}

export const loginSuccess = user => {
    localStorage.setItem('user', JSON.stringify(user))
    history.replace('/dashboard')
    return {
        type: LOGIN_SUCCESS,
        user: user,
    }
}

export const loginFailure = error => {
    handleError(error)
    return {
        type: LOGIN_FAILURE,
        error,
    }
}

export const logout = error => {
    localStorage.removeItem('user')
    history.push('/login')
    return {
        type: LOGOUT,
        error: error,
    }
}

export const recoverPassword = email => {
    const body = {
        email: email,
        url: process.env.REACT_APP_RESET_URL,
    }
    return dispatch => {
        dispatch({type: 'PASSWORD_RECOVER'})
        axios
            .post(`${baseUrl}/auth/forgot-password`, body)
            .then(() => {
                dispatch(recoverPasswordSuccess())
            })
            .catch(error => dispatch(recoverPasswordFailure(error.response.data.data)))
    }
}

export const recoverPasswordSuccess = () => {
    toast.info('Check e-mail adress.')
    return {
        type: PASSWORD_RECOVER_SUCCESS,
    }
}

export const recoverPasswordFailure = error => {
    handleError(error)
    return {
        type: PASSWORD_RECOVER_SUCCESS,
    }
}

export const resetPassword = (password, passwordConfirmation, code) => {
    const body = {
        password: password,
        passwordConfirmation: passwordConfirmation,
        code: code,
    }

    return dispatch => {
        dispatch({type: 'PASSWORD_RESET'})
        axios
            .post(`${baseUrl}/auth/reset-password`, body)
            .then(() => {
                dispatch(resetPasswordSuccess())
            })
            .catch(error => dispatch(resetPasswordFailure(error.response.data.data)))
    }
}

export const resetPasswordSuccess = () => {
    toast.info('Password changed! Please log in.')
    history.push('/login')
    return {
        type: PASSWORD_RESET_SUCCESS,
    }
}

export const resetPasswordFailure = error => {
    handleError(error)
    return {
        type: PASSWORD_RESET_FAILURE,
    }
}
