import * as actionTypes from '../actions/timesheet.actions'
import {clone} from 'ramda'

const initialState = {
    hours: [],
    hoursLoaded: false,
    hoursChanged: true,
}

const timesheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_HOURS_SUCCESS: {
            return {
                ...state,
                hours: action.hours.map(hour => ({...hour, project: hour.project.name})),
                hoursLoaded: true,
            }
        }
        case actionTypes.EDIT_HOURS_SUCCESS: {
            let newHours = clone(state.hours)
            const hourIndex = state.hours.findIndex(hour => hour.id === action.hour.id)
            newHours[hourIndex] = {...action.hour, project: action.hour.project.name}
            return {
                ...state,
                hours: newHours,
            }
        }
        case actionTypes.ADD_HOUR: {
            return {
                ...state,
                hoursChanged: false,
            }
        }
        case actionTypes.ADD_HOUR_SUCCESS: {
            let newHours = clone(state.hours)
            newHours.push({...action.hour, project: action.hour.project.name})
            return {
                ...state,
                hours: newHours,
                hoursChanged: true,
            }
        }
        case actionTypes.DELETE_HOUR: {
            return {
                ...state,
                hoursChanged: false,
            }
        }
        case actionTypes.DELETE_HOUR_SUCCESS: {
            let newHours = clone(state.hours)
            newHours = newHours.filter(hour => action.hourId !== hour.id)

            return {
                ...state,
                hours: newHours,
                hoursChanged: true,
            }
        }
        default:
            return state
    }
}

export default timesheetReducer
