import React from 'react'
import SVG from 'react-inlinesvg'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'

class NewLeave extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            start: new Date(),
            end: new Date(),
            valid: true,
            loading: false,
        }
    }

    formValid = (name, value) => {
        if (value.getTime() < new Date().getTime()) {
            return false
        } else if (name === 'start' && value.getTime() > this.state.end) {
            return false
        } else if (name === 'end' && value.getTime() < this.state.start) {
            return false
        }
        return true
    }

    handleStartDateChange = value => {
        this.setState({...this.state, start: value, valid: this.formValid('start', value)})
    }

    handleEndDateChange = value => {
        this.setState({...this.state, end: value, valid: this.formValid('end', value)})
    }

    add = () => {
        this.props.add(this.state.start, this.state.end)

        this.setState({
            ...this.state,
            start: new Date(),
            end: new Date(),
            loading: true,
        })
    }
    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    render() {
        return (
            <div className="d-flex a-center mt-4">
                <div className="mr-4">
                    <p className="caption">Start date:</p>
                    <CustomDatePicker
                        value={this.state.start}
                        minDate={new Date()}
                        onUpdate={this.handleStartDateChange}
                    />
                </div>
                <div className="mr-4">
                    <p className="caption">End date:</p>
                    <CustomDatePicker value={this.state.end} minDate={new Date()} onUpdate={this.handleEndDateChange} />
                </div>
                <div>
                    <button className="btn btn-circle btn-pink" disabled={!this.state.valid} onClick={this.add}>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
                {this.props.leaveChanged === false ? this.showModal() : this.closeModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    leaveChanged: state.leave.leaveChanged,
})

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewLeave)
