import * as actionTypes from '../actions/history.actions'

const initialState = {
    selectedData: [],
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    monthWorkingHours: '00:00',
    dataLoaded: false,
}

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_HISTORY_SUCCESS: {
            const newSelectedData = action.hours.map(hour => ({...hour, project: hour.project.name}))

            const newMonthWorkingHours = newSelectedData.map(hour => hour.value).reduce((a, b) => a + b, 0)

            return {
                ...state,
                selectedData: newSelectedData,
                selectedMonth: action.month,
                selectedYear: action.year,
                dataLoaded: true,
                monthWorkingHours: newMonthWorkingHours,
            }
        }
        default:
            return state
    }
}

export default historyReducer
