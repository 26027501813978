import React from 'react'
import EmployeesList from '../../components/Employees/EmployeesList'
import {connect} from 'react-redux'
import {
    loadEmployees,
    startEmployeeEdit,
    editEmployee,
    deleteEmployee,
    addEmployee,
} from '../../store/actions/employees.actions'
import {loadContractTypes} from '../../store/actions/types.actions'
import {showModal} from '../../store/actions/modals.actions'
import SVG from 'react-inlinesvg'
import AddEmployeeForm from '../../components/Employees/AddEmployeeForm/AddEmployeeForm'
import Spinner from '../../components/Spinner/Spinner'
import {sortString} from '../../helpers/sorter/sorter'

class Employees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addPanelVisible: false,
            emloyeeToDelete: null,
        }
    }

    componentDidMount() {
        if (!this.props.employeesLoaded) {
            this.props.loadEmployees()
        }
        if (!this.props.contractTypesLoaded) {
            this.props.loadContractTypes()
        }
    }

    onEmployeesUpdate = employee => {
        this.props.startEmployeeEdit(employee)
    }

    onEmployeesSave = employee => {
        this.props.editEmployee(employee)
    }
    confirmEmployeeDelete = () => {
        this.props.deleteEmployee(this.state.emloyeeToDelete)
    }

    onEmployeeDelete = employeeId => {
        this.setState({
            ...this.state,
            emloyeeToDelete: employeeId,
        })

        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this employee?',
                confirm: this.confirmEmployeeDelete,
            },
            'confirm',
        )
    }

    onEmployeeAdd = employee => {
        this.props.addEmployee(employee)
        this.setState({
            ...this.state,
            addPanelVisible: false,
        })
    }

    showPanel = () => {
        this.setState({
            ...this.state,
            addPanelVisible: true,
        })
    }

    sortData() {
        this.props.employees.sort(sortString('username'))
    }

    render() {
        return (
            <div className="page-margin my-4">
                <h1 className="mb-5">Employees</h1>
                {this.sortData()}
                {this.props.employeesLoaded && this.props.contractTypesLoaded && (
                    <EmployeesList
                        data={this.props.employees}
                        contractTypes={this.props.contractTypes}
                        update={this.onEmployeesUpdate}
                        save={this.onEmployeesSave}
                        delete={this.onEmployeeDelete}></EmployeesList>
                )}
                {(!this.props.employeesLoaded || !this.props.contractTypesLoaded) && <Spinner />}
                {this.state.addPanelVisible && this.props.employeesLoaded && this.props.contractTypesLoaded && (
                    <AddEmployeeForm
                        contractTypes={this.props.contractTypes}
                        add={this.onEmployeeAdd}></AddEmployeeForm>
                )}
                {!this.state.addPanelVisible && this.props.employeesLoaded && this.props.contractTypesLoaded && (
                    <div className="d-flex">
                        <button className="btn btn-pink btn-circle mx-auto mt-4" onClick={this.showPanel}>
                            <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        employees: state.employees.employees,
        employeesLoaded: state.employees.employeesLoaded,
        contractTypesLoaded: state.types.contractTypesLoaded,
        contractTypes: state.types.contractTypes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadEmployees: () => dispatch(loadEmployees()),
        loadContractTypes: () => dispatch(loadContractTypes()),
        startEmployeeEdit: employeeId => dispatch(startEmployeeEdit(employeeId)),
        editEmployee: employee => dispatch(editEmployee(employee)),
        deleteEmployee: employeeId => dispatch(deleteEmployee(employeeId)),
        addEmployee: employee => dispatch(addEmployee(employee)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Employees)
