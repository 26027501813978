import React from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import SVG from 'react-inlinesvg'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'
import {sortDate} from '../../../helpers/sorter/sorter'

class UserLeave extends React.Component {
    columns = [
        {
            dataField: 'start',
            text: 'Start date',
            editable: !this.props.dateSelectingDisabled,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
            editorRenderer: (editorProps, value) => <CustomDatePicker isOpen={true} {...editorProps} value={value} />,
        },
        {
            dataField: 'end',
            text: 'End date',
            editable: !this.props.dateSelectingDisabled,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
            editorRenderer: (editorProps, value) => <CustomDatePicker isOpen={true} {...editorProps} value={value} />,
        },
        {
            dataField: 'accepted',
            text: 'Accepted',
            editable: false,
            formatter: cellContent => {
                return <input type="checkbox" disabled checked={cellContent}></input>
            },
        },
        {
            dataField: 'delete',
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                if (new Date(row.start).getTime() > new Date().getTime()) {
                    return (
                        <button className="btn btn-circle btn-pink align-right" onClick={() => this.deleteRow(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                        </button>
                    )
                }
            },
        },
    ]

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    deleteRow(row) {
        for (let i = 0; i < this.props.leave.length; i++) {
            if (this.props.leave[i].id === row.id && this.props.leave[i].accepted) {
                this.props.showModal(
                    {
                        open: true,
                        message:
                            "You can't delete accepted leave. If You want to cancel your leave please contact administrator.",
                        confirm: () => hideModal(),
                    },
                    'confirm',
                )
            } else if (this.props.leave[i].id === row.id && !this.props.leave[i].accepted) {
                this.props.showModal(
                    {
                        open: true,
                        message: 'Are You sure You want to delete this leave?',
                        confirm: () => this.props.deleteLeave(row.id),
                    },
                    'confirm',
                )
            }
        }
    }

    sortData() {
        this.props.leave.sort(sortDate('start'))
    }

    render() {
        return (
            <div>
                {this.sortData()}
                {this.props.leaveChanged === false ? this.showModal() : this.closeModal()}
                {!!this.props.leave.length && <DataGrid columns={this.columns} data={this.props.leave}></DataGrid>}
                {!this.props.leave.length && <p className="mt-3">No pending leave reported</p>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        leaveChanged: state.leave.leaveChanged,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLeave)
