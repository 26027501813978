import React, {Component} from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import NumberInput from '../../../ui/NumberInput/NumberInput'
import {dayNames} from '../../../helpers'
import TwoPartTitle from '../../../ui/TwoPartTitle/TwoPartTitle'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import {loadHours, editHour, addHour, deleteHour} from '../../../store/actions/timesheet.actions'
import {loadProjectList} from '../../../store/actions/types.actions'
import {connect} from 'react-redux'
import NewHour from '../NewHour/NewHour'
import SVG from 'react-inlinesvg'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import Spinner from '../../Spinner/Spinner'
import {sortString} from '../../../helpers/sorter/sorter'

class WeekGrid extends Component {
    componentDidMount() {
        this.props.loadHours()
        this.props.loadProjectList()
    }

    updateHandler = row => {
        this.props.editHour({
            ...row,
            project: this.props.projects.find(project => project.label === row.project)
                ? this.props.projects.find(project => project.label === row.project).value
                : '',
        })
    }

    add = hour => {
        this.props.addHour(hour)
    }

    handleDelete = hour => {
        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this record?',
                confirm: () => this.props.deleteHour(hour.id),
            },
            'confirm',
        )
    }

    columns = [
        {
            dataField: 'project',
            text: 'Project',
            editorRenderer: (editorProps, value) => {
                const selected = this.props.projects.find(opt => opt.label === value)

                return (
                    <CustomSelect
                        {...editorProps}
                        selected={selected ? selected : {value: '', label: ''}}
                        options={this.props.projects}></CustomSelect>
                )
            },
        },
        {
            dataField: 'date',
            text: 'Date',
            editable: false,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'task',
            text: 'task',
        },
        {
            dataField: 'description',
            text: 'description',
        },
        {
            dataField: 'value',
            text: 'hours',
            editorRenderer: (editorProps, value) => (
                <NumberInput onUpdate={editorProps.onUpdate} value={value} type="hour"></NumberInput>
            ),
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Delete',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right" onClick={() => this.handleDelete(row)}>
                        <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                    </button>
                )
            },
        },
    ]

    createGrid = () => {
        let children = []
        for (let i = 0; i < 7; i++) {
            const data = this.props.hours.filter(
                hour => new Date(hour.date).setHours(0, 0, 0, 0) === this.getPrevDate(i).setHours(0, 0, 0, 0),
            )
            const hourCount = data.map(hour => hour.value).reduce((a, b) => a + b, 0)
            children.push(
                <div key={i} className="mb-6 hourGrid">
                    <TwoPartTitle
                        firstPart={dayNames[(new Date().getDay() + 7 - i) % 7]}
                        secondPart={hourCount + 'h'}></TwoPartTitle>
                    {!!data.length && (
                        <DataGrid rowClasses="form-control-table" columns={this.columns} data={data} updated={this.updateHandler}></DataGrid>
                    )}
                    <NewHour
                        projects={this.props.projects}
                        date={this.getPrevDate(i)}
                        lastProject={
                            this.props.hours.length > 0 ? this.props.hours[this.props.hours.length - 1].project : null
                        }
                        addHour={this.add}></NewHour>
                </div>,
            )
        }
        return children
    }

    getPrevDate = offset => {
        var d = new Date()
        d.setDate(d.getDate() - offset)
        return d
    }

    sortData() {
        this.props.projects.sort(sortString('label'))
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    render() {
        return (
            <div className="WeekGrid">
                {this.props.hoursChanged === false ? this.showModal() : this.closeModal()}
                {this.props.projectsLoaded ? (
                    <div>
                        {this.sortData()}
                        {this.createGrid()}
                    </div>
                ) : (
                    <Spinner />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hours: state.timesheet.hours,
    hoursLoaded: state.timesheet.hoursLoaded,
    projects: state.types.projects,
    projectsLoaded: state.types.projectsLoaded,
    hoursChanged: state.timesheet.hoursChanged,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: (month, year) => dispatch(loadHours(month, year)),
        loadProjectList: () => dispatch(loadProjectList()),
        editHour: hour => dispatch(editHour(hour)),
        addHour: hour => dispatch(addHour(hour)),
        deleteHour: hourId => dispatch(deleteHour(hourId)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekGrid)
