import * as actionTypes from '../actions/report.actions'

const initialState = {
    report: '',
    reportLoaded: false,
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_REPORT_SUCCESS: {
            return {
                ...state,
                report: action.report,
                reportLoaded: true,
            }
        }
        default:
            return state
    }
}

export default reportReducer
