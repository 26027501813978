import axios from 'axios'
import {store} from '../../App'
import {handleError} from '../../helpers'
import moment from 'moment'
import {combineLatest} from 'rxjs'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_HOURS = 'LOAD_HOURS'
export const LOAD_HOURS_SUCCESS = 'LOAD_HOURS_SUCCESS'
export const LOAD_HOURS_FAILURE = 'LOAD_HOURS_FAILURE'

export const LOAD_PAST_MONTH_HOURS = 'LOAD_PAST_MONTH_HOURS'
export const LOAD_PAST_MONTH_HOURS_SUCCESS = 'LOAD_PAST_MONTH_HOURS_SUCCESS'
export const LOAD_PAST_MONTH_HOURS_FAILURE = 'LOAD_HOURS_FAILURE'

export const EDIT_HOURS = 'EDIT_HOURS'
export const EDIT_HOURS_SUCCESS = 'EDIT_HOURS_SUCCESS'
export const EDIT_HOURS_FAILURE = 'EDIT_HOURS_FAILURE'

export const ADD_HOUR = 'ADD_HOUR'
export const ADD_HOUR_SUCCESS = 'ADD_HOUR_SUCCESS'
export const ADD_HOUR_FAILURE = 'ADD_HOUR_FAILURE'

export const DELETE_HOUR = 'DELETE_HOUR'
export const DELETE_HOUR_SUCCESS = 'DELETE_HOUR_SUCCESS'
export const DELETE_HOUR_FAILURE = 'DELETE_HOUR_FAILURE'

export const loadHours = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    const body = {
        query: ` query
                    {
                    hours (where: {
                    date_gte: "${moment()
                        .subtract(1, 'M')
                        .startOf('month')
                        .format('YYYY-MM-DD')}", 
                    date_lt: "${moment()
                        .startOf('month')
                        .format('YYYY-MM-DD')}", 
                    user: "${store.getState().auth.user.id}"})
                    {
                        id
                        description
                        task
                        date
                        value
                        project {name}
                    }
                }
            `,
    }

    const lastMonth = axios.post(`${baseUrl}/graphql`, body, config)
    const thisMonth = axios.post(
        `${baseUrl}/graphql`,
        {
            query: ` query
                    {
                    hours (where: {
                    date_gte: "${moment()
                        .startOf('month')
                        .format('YYYY-MM-DD')}", 
                    date_lt: "${moment()
                        .add(1, 'M')
                        .startOf('month')
                        .format('YYYY-MM-DD')}", 
                    user: "${store.getState().auth.user.id}"} sort: "date")
                    {
                        id
                        description
                        task
                        date
                        value
                        project {name}
                    }
                }
            `,
        },
        config,
    )

    return dispatch => {
        dispatch({type: LOAD_HOURS})
        combineLatest([lastMonth, thisMonth]).subscribe(
            ([lastMonthRes, thisMonthRes]) =>
                dispatch(loadHoursSuccess([...lastMonthRes.data.data.hours, ...thisMonthRes.data.data.hours])),
            error => dispatch(loadHoursFailure(error.response.data.data)),
        )
    }
}

export const loadHoursSuccess = hours => {
    return {
        type: LOAD_HOURS_SUCCESS,
        hours,
    }
}

export const loadHoursFailure = error => {
    handleError(error)
    return {
        type: LOAD_HOURS_FAILURE,
        error: error,
    }
}

export const editHour = hour => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        value: hour.value,
        description: hour.description,
        task: hour.task,
        date: new Date(hour.date).toISOString().slice(0, 10),
        project: hour.project,
        user: store.getState().auth.user.id,
    }

    return dispatch => {
        dispatch({type: EDIT_HOURS})
        axios
            .put(`${baseUrl}/hours/${hour.id}`, body, config)
            .then(response => {
                dispatch(editHourSuccess(response.data))
            })
            .catch(error => dispatch(editHourFailure(error.response.data.data)))
    }
}

export const editHourSuccess = hour => {
    return {
        type: EDIT_HOURS_SUCCESS,
        hour,
    }
}

export const editHourFailure = error => {
    handleError(error)
    return {
        type: EDIT_HOURS_FAILURE,
        error: error,
    }
}

export const addHour = hour => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: ADD_HOUR})
        axios
            .post(
                `${baseUrl}/hours`,
                {...hour, date: moment(hour.date).format('YYYY-MM-DD'), user: store.getState().auth.user.id},
                config,
            )
            .then(response => {
                dispatch(addHourSuccess(response.data))
            })
            .catch(error => dispatch(addHourFailure(error.response.data.data)))
    }
}

export const addHourSuccess = hour => {
    return {
        type: ADD_HOUR_SUCCESS,
        hour,
        hoursAdded: false,
    }
}

export const addHourFailure = error => {
    handleError(error)
    return {
        type: ADD_HOUR_FAILURE,
        error: error,
    }
}

export const deleteHour = hourId => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_HOUR})
        axios
            .delete(`${baseUrl}/hours/${hourId}`, config)
            .then(() => {
                dispatch(deleteHourSuccess(hourId))
            })
            .catch(error => dispatch(deleteHourFailure(error.response.data.data)))
    }
}

export const deleteHourSuccess = hourId => {
    return {
        type: DELETE_HOUR_SUCCESS,
        hourId,
    }
}

export const deleteHourFailure = error => {
    handleError(error)
    return {
        type: DELETE_HOUR_FAILURE,
        error: error,
    }
}
