import {toast} from 'react-toastify'

export const handleError = error => {
    if (error.errors && error.errors.value && error.errors.value[0]) {
        toast.error(error.errors.value[0])
    } else if (error[0] && error[0].messages && error[0].messages[0] && error[0].messages[0].message) {
        toast.error(error[0].messages[0].message)
    } else {
        JSON.stringify(error)
    }
}
