import React from 'react'
import styles from './addEmployeeForm.module.scss'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import SVG from 'react-inlinesvg'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'
import {store} from '../../../App'

class AddEmployeeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            email: '',
            blocked: false,
            confirmed: true,
            name: '',
            surname: '',
            vacationDays: 0,
            additionalCosts: 0,
            tax: 0,
            contractType: this.props.contractTypes[0].value,
            hours: [],
            costs: [],
            billingType: 'hourly',
            valid: false,
            company: store.getState().auth.user.companyId,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleContractChange = this.handleContractChange.bind(this)
    }

    formValid = (name, value) => {
        const regex = /^\S+@\S+\.\S+$/

        const empty = ['username', 'email', 'additionalCosts', 'tax', 'vacationDays'].filter(
            elm => elm !== name && this.state[elm] === '',
        )

        if (name === 'email' && !regex.test(value)) {
            return false
        }

        return empty.length === 0 && value !== ''
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({[name]: value, valid: this.formValid(name, value)})
    }

    handleContractChange(value) {
        this.setState({
            ...this.state,
            contractType: value,
            valid: this.formValid(),
        })
    }

    add = () => {
        this.props.add(this.state)
        this.setState({
            ...this.state,
            username: '',
            email: '',
            vacationDays: 0,
            additionalCosts: 0,
            tax: 0,
            contractType: this.props.contractTypes[0].value,
            valid: false,
        })
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
        setTimeout(() => {
            this.closeModal()
        }, 5000)
    }

    render() {
        return (
            <div className={styles.detailsHeader}>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Username:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Name:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Surname:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="surname"
                        value={this.state.surname}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>E-mail:</p>
                    <input
                        type="email"
                        className={'form-control ' + styles.formControl}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Vacation Days:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="vacationDays"
                        value={this.state.vacationDays}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Additional Costs:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="additionalCosts"
                        value={this.state.additionalCosts}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Tax:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="tax"
                        value={this.state.tax}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Contract type:</p>
                    <CustomSelect
                        selected={this.props.contractTypes[0]}
                        options={this.props.contractTypes}
                        change={this.handleContractChange}></CustomSelect>
                </div>
                {this.state.valid && (
                    <div className={styles.addButton}>
                        <button
                            className="btn btn-circle btn-pink"
                            onClick={() => {
                                this.add()
                                this.showModal()
                            }}>
                            <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        employeeAdded: state.employees.employeeAdded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeForm)
