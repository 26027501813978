import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import styles from './projectDetailsNewCost.module.scss'
import SVG from 'react-inlinesvg'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'
import {sortString} from '../../../helpers/sorter/sorter'

class ProjectDetailsNewCost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            employee: null,
            position: null,
            internalRate: 0,
            externalRate: 0,
        }
        this.employeeInput = React.createRef()
        this.positionInput = React.createRef()

        this.handleChange = this.handleChange.bind(this)
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this)
        this.handlePositionChange = this.handlePositionChange.bind(this)
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleEmployeeChange(value) {
        this.setState({
            ...this.state,
            employee: value,
        })
    }

    handlePositionChange(value) {
        this.setState({
            ...this.state,
            position: value,
        })
    }

    addCost() {
        this.props.add(this.state)
        this.setState({
            employee: null,
            position: null,
            internalRate: 0,
            externalRate: 0,
        })
        this.employeeInput.current.updateSelect({value: '', label: ''})
        this.positionInput.current.updateSelect({value: '', label: ''})
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    sortData() {
        this.props.employees.sort(sortString('label'))
    }

    render() {
        return (
            <div className={styles.container}>
                {this.sortData()}
                <div className={styles.controlContainer}>
                    <CustomSelect
                        searchable
                        ref={this.employeeInput}
                        className={'form-control'}
                        selected={{value: '', label: ''}}
                        options={this.props.employees}
                        change={this.handleEmployeeChange}></CustomSelect>
                </div>
                <div className={styles.controlContainer}>
                    <CustomSelect
                        searchable
                        ref={this.positionInput}
                        className={'form-control'}
                        selected={{value: '', label: ''}}
                        options={this.props.positions}
                        change={this.handlePositionChange}></CustomSelect>
                </div>
                <div className={styles.controlContainer}>
                    <input
                        type="number"
                        min="0"
                        className={'form-control'}
                        name="internalRate"
                        value={this.state.internalRate}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <input
                        type="number"
                        min="0"
                        className={'form-control'}
                        name="externalRate"
                        value={this.state.externalRate}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <button
                        className="btn btn-circle btn-pink align-right"
                        onClick={() => this.addCost()}
                        disabled={!this.state.employee || !this.state.position}>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
                {this.props.projectAdded === false ? this.showModal() : this.closeModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    projectAdded: state.projects.projectAdded,
})

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsNewCost)
