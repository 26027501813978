import React from 'react'
import styles from './login.module.scss'
import {login} from '../../store/actions/authentication.actions'
import {connect} from 'react-redux'
import SVG from 'react-inlinesvg'
import {NavLink} from 'react-router-dom'

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.login(this.state.email, this.state.password)
    }

    render() {
        const {email, password} = this.state
        return (
            <div className="form-container">
                <div className="form">
                    <div className="logo">
                        {this.props.loading ? (
                            <SVG src="/assets/spinner.svg" />
                        ) : (
                            <img src="/assets/logo_timesheet.png" alt="Logo" />
                        )}
                    </div>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className="labeled-input mb-4">
                            <input
                                type="text"
                                className="formControl"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="email">E-mail</label>
                        </div>
                        <div className="labeled-input mb-2">
                            <input
                                type="password"
                                className="formControl"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className={styles.link}>
                            <NavLink to="/recovery">
                                <span>Forgot password</span>
                            </NavLink>
                        </div>
                        <div className="btn_container">
                            <button className="btn btn-pink py-2 mt-4'">
                                <SVG className="icon" src="/assets/icons/arrow-right.svg" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        loading: state.auth.loading,
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (email, password) => dispatch(login(email, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
