import React from 'react'
import styles from './Report.module.scss'
import {loadReport} from '../../store/actions/report.actions'
import {connect} from 'react-redux'
import Spinner from '../../components/Spinner/Spinner'

class Report extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addPanelVisible: false,
            report: this.props.report,
            reportLoaded: this.props.reportLoaded,
        }
    }

    componentDidMount() {
        if (!this.props.reportLoaded) {
            this.props.loadReport()
            if (this.props.report === undefined) this.props.report = 'Brak raportu do wyświetlenia'
        } else {
            this.setState({addPanelVisible: true})
        }
    }

    render() {
        return (
            <div className={styles.container} id={'iframe_container'}>
                {document.getElementById('iframe_container') != null ? (
                    !this.props.reportLoaded ? (
                        <Spinner margin_top={'30%'} />
                    ) : (
                        <div className={styles.container} dangerouslySetInnerHTML={{__html: this.props.report}}></div>
                    )
                ) : (
                    <Spinner margin_top={'30%'} />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        report: state.report.report,
        reportLoaded: state.report.reportLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadReport: () => dispatch(loadReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
