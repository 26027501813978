export const sortString = sortBy => (a, b) => {
    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return 1
    } else if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return -1
    }
    return 0
}

export const sortDate = sortBy => (a, b) => {
    let date_a = new Date(a[sortBy])
    let date_b = new Date(b[sortBy])
    return date_a.getTime() - date_b.getTime()
}
