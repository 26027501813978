import * as actionTypes from '../actions/leave.actions'
import {clone} from 'ramda'

const initialState = {
    userLeave: [],
    leave: [],
    leavesLoaded: false,
    leaveChanged: true,
    leaveAccepted: true,
}

const leaveReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_USER_LEAVE_SUCCESS: {
            return {
                ...state,
                leavesLoaded: true,
                userLeave: action.leave,
            }
        }
        case actionTypes.LOAD_ALL_LEAVE_SUCCESS: {
            return {
                ...state,
                leavesLoaded: true,
                leave: action.leave.map(leave => ({...leave, user: leave.user ? leave.user.username : null})),
            }
        }
        case actionTypes.ACCEPT_LEAVE: {
            return {
                ...state,
                leaveAccepted: false,
            }
        }
        case actionTypes.ACCEPT_LEAVE_SUCCESS: {
            let newLeave = clone(state.leave)
            const leaveIndex = newLeave.findIndex(leave => leave.id === action.leaveId)
            newLeave[leaveIndex].accepted = true

            return {
                ...state,
                leave: newLeave,
                leaveAccepted: true,
            }
        }
        case actionTypes.ADD_LEAVE: {
            return {
                ...state,
                leaveChanged: false,
            }
        }
        case actionTypes.ADD_LEAVE_SUCCESS: {
            let newLeave = clone(state.userLeave)
            newLeave.push(action.leave)
            return {
                ...state,
                userLeave: newLeave,
                leaveChanged: true,
            }
        }
        case actionTypes.DELETE_LEAVE: {
            return {
                ...state,
                leaveChanged: false,
            }
        }
        case actionTypes.DELETE_LEAVE_SUCCESS: {
            let newLeave = clone(state.userLeave)
            const leaveIndex = newLeave.findIndex(leave => leave.id === action.leaveId)
            newLeave.splice(leaveIndex, 1)

            return {
                ...state,
                userLeave: newLeave,
                leaveChanged: true,
            }
        }
        default:
            return state
    }
}

export default leaveReducer
