import * as actionTypes from '../actions/employees.actions'
import {clone} from 'ramda'
import {mapStrapiUser} from '../../helpers/mapStrapiObjects'

const initialState = {
    employees: [],
    employeeNames: [],
    employeesLoaded: false,
    employeeAdded: true,
}

const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EMPLOYEES_SUCCESS: {
            return {
                ...state,
                employeeNames: action.employees.users.map(employee => ({
                    value: employee.id,
                    label: employee.username,
                })),
                employees: action.employees.users.map(user => ({
                    ...user,
                    contractType: user.contractType ? user.contractType.id : '',
                    tax: user.tax ? user.tax : 0,
                    additionalCosts: user.additionalCosts ? user.additionalCosts : 0,
                    edited: false,
                })),
                employeesLoaded: true,
            }
        }
        case actionTypes.START_EMPLOYEE_EDIT: {
            let newEmployees = clone(state.employees)
            const employeeIndex = state.employees.findIndex(employee => employee.id === action.employeeId)
            newEmployees[employeeIndex].edited = true

            return {
                ...state,
                employees: newEmployees,
            }
        }
        case actionTypes.EDIT_EMPLOYEE_SUCCESS: {
            let newEmployees = clone(state.employees)
            const employeeIndex = state.employees.findIndex(employee => employee.id === action.employee.id)
            newEmployees[employeeIndex] = mapStrapiUser(action.employee)
            newEmployees[employeeIndex].edited = false

            return {
                ...state,
                employees: newEmployees,
            }
        }
        case actionTypes.DELETE_EMPLOYEE_SUCCESS: {
            let newEmployees = clone(state.employees)
            const employeeIndex = state.employees.findIndex(employee => employee.id === action.employeeId)
            newEmployees.splice(employeeIndex, 1)

            return {
                ...state,
                employees: newEmployees,
            }
        }
        case actionTypes.ADD_EMPLOYEE: {
            return {
                ...state,
                employeeAdded: false,
            }
        }
        case actionTypes.ADD_EMPLOYEE_SUCCESS: {
            let newEmployees = clone(state.employees)
            newEmployees.push(mapStrapiUser(action.employee))
            return {
                ...state,
                employees: newEmployees,
                employeeAdded: true,
            }
        }
        default:
            return state
    }
}

export default employeesReducer
