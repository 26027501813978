import React, {Component} from 'react';
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './infoPopup.module.scss';

class InfoPopover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            message: this.props.message,
            placement: this.props.placement,
        }
    }

    popover() {
        return <Popover id="popover-basic">
            <Popover.Header>{this.state.title}</Popover.Header>
            <Popover.Body>
                {this.state.message}
            </Popover.Body>
        </Popover>
    }

    render() {
        return <div className={styles.floatingButton}>
            <OverlayTrigger trigger="click" placement={this.state.placement} overlay={this.popover()}>
                <Button className={styles.info}>
                    <p className={styles.btnTitle}>Help</p>
                </Button>
            </OverlayTrigger>
        </div>;
    }
}

export default InfoPopover;