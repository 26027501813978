import React from 'react'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import {sortDate} from '../../../helpers/sorter/sorter'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'

class AllLeave extends React.Component {
    columns = [
        {
            dataField: 'user',
            text: 'User',
            editable: false,
        },
        {
            dataField: 'start',
            text: 'Start date',
            editable: false,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'end',
            text: 'End date',
            editable: false,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'accepted',
            text: 'Accepted',
            editable: false,
            formatter: cellContent => {
                return <input type="checkbox" disabled checked={cellContent}></input>
            },
        },
        {
            dataField: 'Accept',
            text: 'Accept',
            isDummyField: true,
            editable: false,
            formatter: (cellContent, row) => {
                if (!row.accepted) {
                    return (
                        <button
                            className="btn btn-rectangle btn-pink align-right"
                            onClick={() => {
                                this.props.acceptLeave(row.id)
                            }}>
                            Accept
                        </button>
                    )
                }
            },
        },
    ]

    sortData() {
        this.props.leave.sort(sortDate('start'))
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    closeModal() {
        this.props.hideModal()
    }

    render() {
        return (
            <div>
                {this.sortData()}
                {!this.props.leaveAccepted ? this.showModal() : this.closeModal()}
                <h2 className="mt-5">All leave</h2>
                <DataGrid columns={this.columns} data={this.props.leave}></DataGrid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        leaveAccepted: state.leave.leaveAccepted,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeave)
