import React from 'react'
import {loadUserLeave, addLeave, deleteLeave, loadAllLeave, acceptLeave} from '../../store/actions/leave.actions'
import {connect} from 'react-redux'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import NewLeave from '../../components/Leave/NewLeave/NewLeave'
import UserLeave from '../../components/Leave/UserLeave/UserLeave'
import AllLeave from '../../components/Leave/AllLeave/AllLeave'
import moment from 'moment'
import Spinner from '../../components/Spinner/Spinner'
import {showModal} from '../../store/actions/modals.actions'

class Leave extends React.Component {
    componentDidMount() {
        this.props.loadUserLeave()
        if (this.props.role === 'Administrator') {
            this.props.loadAllLeave()
        }
    }

    getLeaveDaysLeft = () => {
        const daysLeft =
            this.props.leaveDays -
            this.props.userLeave
                .map(
                    leave => (new Date(leave.end).getTime() - new Date(leave.start).getTime()) / (1000 * 3600 * 24) + 1,
                )
                .reduce((a, b) => a + b, 0)

        return daysLeft > 1 ? daysLeft.toString() + ' days' : daysLeft === 1 ? daysLeft.toString() + ' day' : '0 days'
    }

    deleteRow(row) {
        if (!this.props.leave.accepted) this.props.deleteLeave(row.id)
    }

    acceptLeave() {
        if (!this.props.acceptedLeave) showModal()
        this.props.acceptLeave()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    render() {
        return (
            <div className="page-margin my-4">
                <h1 className="mb-4">Leave</h1>
                <TwoPartTitle firstPart="Paid leave left" secondPart={this.getLeaveDaysLeft()}></TwoPartTitle>
                <h2 className="mt-4">Your Leave</h2>
                {!this.props.leavesLoaded ? (
                    <Spinner />
                ) : (
                    <UserLeave
                        deleteLeave={this.props.deleteLeave}
                        leave={this.props.userLeave.filter(leave => moment(leave.end) > moment())}></UserLeave>
                )}
                <NewLeave add={this.props.addLeave}></NewLeave>
                <h3 className="mt-4">Completed</h3>
                {!this.props.leavesLoaded ? (
                    <Spinner />
                ) : (
                    <UserLeave
                        deleteLeave={this.props.deleteLeave}
                        dateSelectingDisabled={true}
                        leave={this.props.userLeave.filter(leave => moment(leave.end) <= moment())}></UserLeave>
                )}
                {this.props.role === 'Administrator' &&
                    (!this.props.leavesLoaded ? (
                        <Spinner />
                    ) : (
                        <>
                            <h3 className="mt-2">Report a new leave:</h3>
                            <AllLeave leave={this.props.leave} acceptLeave={this.props.acceptLeave}></AllLeave>
                        </>
                    ))}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.auth.user.role,
        userLeave: state.leave.userLeave,
        leave: state.leave.leave,
        leaveDays: state.auth.user.leaveDays,
        leavesLoaded: state.leave.leavesLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUserLeave: () => dispatch(loadUserLeave()),
        deleteLeave: leaveId => dispatch(deleteLeave(leaveId)),
        addLeave: (start, end) => dispatch(addLeave(start, end)),
        loadAllLeave: () => dispatch(loadAllLeave()),
        acceptLeave: leaveId => dispatch(acceptLeave(leaveId)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leave)
