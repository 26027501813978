import React, {Component} from 'react'
import DataGrid from '../../ui/DataGrid/DataGrid'
import {monthNames} from '../../helpers'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import range from 'ramda/src/range'
import reverse from 'ramda/src/reverse'
import CustomSelect from '../../ui/CustomSelect/CustomSelect'
import './HistoryGrid.scss'
import {connect} from 'react-redux'
import {loadHistory} from '../../store/actions/history.actions'
import Spinner from '../Spinner/Spinner'

class HistoryGrid extends Component {
    componentDidMount() {
        this.props.loadHours(this.props.selectedMonth, this.props.selectedYear)
    }

    onYearChange = year => {
        this.props.loadHours(this.props.selectedMonth, year)
    }

    onMonthChange = month => {
        this.props.loadHours(month, this.props.selectedYear)
    }

    columns = [
        {
            dataField: 'project',
            text: 'Project',
            editable: false,
        },
        {
            dataField: 'date',
            text: 'Date',
            editable: false,
            formatter: cell => {
                let dateObj = cell
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell)
                }
                return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                    -2,
                )}/${dateObj.getUTCFullYear()}`
            },
        },
        {
            dataField: 'task',
            text: 'task',
            editable: false,
        },
        {
            dataField: 'description',
            text: 'description',
            editable: false,
        },
        {
            dataField: 'value',
            text: 'value',
            editable: false,
        },
    ]

    render() {
        const months = monthNames.map((name, id) => {
            return {label: name, value: id}
        })

        const y = reverse(range(2016, new Date().getFullYear() + 1))
        const years = y.map(name => {
            return {label: name, value: name}
        })

        return (
            <div className="hourGrid">
                <div className="row">
                    <TwoPartTitle
                        firstPart={monthNames[this.props.selectedMonth]}
                        secondPart={`${this.props.monthWorkingHours} h`}></TwoPartTitle>

                    {this.props.dataLoaded ? (
                        <div className="filters">
                            <div className="mr-2">
                                <CustomSelect
                                    change={this.onMonthChange}
                                    options={months}
                                    closeOnSelect
                                    selected={{
                                        label: monthNames[this.props.selectedMonth],
                                        value: this.props.selectedMonth,
                                    }}></CustomSelect>
                            </div>
                            <CustomSelect
                                options={years}
                                change={this.onYearChange}
                                closeOnSelect
                                selected={{
                                    label: this.props.selectedYear,
                                    value: this.props.selectedYear,
                                }}></CustomSelect>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
                {this.props.selectedData.length ? (
                    <DataGrid columns={this.columns} data={this.props.selectedData}></DataGrid>
                ) : (
                    this.props.dataLoaded && <p>No data.</p>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    data: state.history.data,
    selectedData: state.history.selectedData,
    selectedMonth: state.history.selectedMonth,
    selectedYear: state.history.selectedYear,
    dataLoaded: state.history.dataLoaded,
    monthWorkingHours: state.history.monthWorkingHours,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: (month, year) => dispatch(loadHistory(month, year)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryGrid)
