import axios from 'axios'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS'
export const LOAD_EMPLOYEES_FAILURE = 'LOAD_EMPLOYEES_FAILURE'

export const START_EMPLOYEE_EDIT = 'START_EMPLOYEE_EDIT'

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE'

export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE'
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS'
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'

export const loadEmployees = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    const body = {
        query: `
                query {
                    users (sort: "name:asc", where: {
                        company: "${store.getState().auth.user.companyId}"}) { 
                        id
                        username
                        additionalCosts
                        tax
                        vacationDays
                        blocked
                        contractType {
                            id
                        }
                    }
                }
            `,
    }
    return dispatch => {
        dispatch({type: LOAD_EMPLOYEES})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadEmployeesSuccess(response.data.data))
            })
            .catch(error => dispatch(loadEmployeesFailure(error.response.data.data)))
    }
}

export const loadEmployeesSuccess = employees => {
    return {
        type: LOAD_EMPLOYEES_SUCCESS,
        employees,
    }
}

export const loadEmployeesFailure = error => {
    handleError(error)
    return {
        type: LOAD_EMPLOYEES_FAILURE,
        error: error,
    }
}

export const startEmployeeEdit = employeeId => {
    return {
        type: START_EMPLOYEE_EDIT,
        employeeId,
    }
}

export const editEmployee = employee => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    const graphEmployee = `{contractType: "${employee.contractType}", additionalCosts: ${employee.additionalCosts }, tax: ${employee.tax}, vacationDays: ${employee.vacationDays}, blocked: ${employee.blocked === 'No' ? false : true }}`

    const body = {
        query: `mutation {
                updateUser (input: {
                where: {
                id: "${employee.id}",
                },
                data: ${graphEmployee}
            }){
                user {
                id
                contractType {id}
                email
                username
                additionalCosts
                tax
                blocked
                vacationDays
                }
            }
         }`,
    }

    return dispatch => {
        dispatch({type: EDIT_EMPLOYEE})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(editEmployeeSuccess(response.data.data.updateUser.user))
            })
            .catch(error => dispatch(editEmployeeFailure(error.response.data.data)))
    }
}

export const editEmployeeSuccess = employee => {
    return {
        type: EDIT_EMPLOYEE_SUCCESS,
        employee,
    }
}

export const editEmployeeFailure = error => {
    handleError(error)
    return {
        type: EDIT_EMPLOYEE_FAILURE,
        error,
    }
}

export const deleteEmployee = employeeId => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_EMPLOYEE})
        axios
            .delete(`${baseUrl}/users/${employeeId}`, config)
            .then(() => {
                dispatch(deleteEmployeeSuccess(employeeId))
            })
            .catch(error => dispatch(deleteEmployeeFailure(error.response.data.data)))
    }
}

export const deleteEmployeeSuccess = employeeId => {
    return {
        type: DELETE_EMPLOYEE_SUCCESS,
        employeeId,
    }
}

export const deleteEmployeeFailure = error => {
    handleError(error)
    return {
        type: DELETE_EMPLOYEE_FAILURE,
        error: error,
    }
}

export const addEmployee = employee => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: ADD_EMPLOYEE})
        axios
            .post(`${baseUrl}/auth/local/register`, employee, config)
            .then(response => {
                dispatch(addEmployeeSuccess(response.data.user))
            })
            .catch(error => dispatch(addEmployeeFailure(error.response.data.data)))
    }
}

export const addEmployeeSuccess = employee => {
    return {
        type: ADD_EMPLOYEE_SUCCESS,
        employee,
    }
}

export const addEmployeeFailure = error => {
    handleError(error)
    return {
        type: ADD_EMPLOYEE_FAILURE,
        error: error,
    }
}
