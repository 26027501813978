import {store} from '../../App'
import axios from 'axios'
import {handleError} from '../../helpers'
import moment from 'moment'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_HISTORY = 'LOAD_HISTORY'
export const LOAD_HISTORY_SUCCESS = 'LOAD_HISTORY_SUCCESS'
export const LOAD_HISTORY_FAILURE = 'LOAD_HISTORY_FAILURE'

export const loadHistory = (selectedMonth, selectedYear) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const body = {
            query: ` query
                    {
                    hours (where: {
                    date_gte: "${moment()
                        .month(selectedMonth)
                        .year(selectedYear)
                        .format('YYYY-MM')}", 
                    date_lt: "${moment()
                        .month(selectedMonth)
                        .year(selectedYear)
                        .add(1, 'months')
                        .format('YYYY-MM')}", 
                    user: "${store.getState().auth.user.id}"})
                    {
                        id
                        description
                        date
                        value
                        task
                        project {name}
                    }
                }
            `,
        }
        dispatch({type: LOAD_HISTORY})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadHistorySuccess(response.data.data.hours, selectedMonth, selectedYear))
            })
            .catch(error => dispatch(loadHistoryFailure(error.response.data.data)))
    }
}

export const loadHistorySuccess = (hours, month, year) => {
    return {
        type: LOAD_HISTORY_SUCCESS,
        hours,
        month,
        year,
    }
}

export const loadHistoryFailure = error => {
    handleError(error)
    return {
        type: LOAD_HISTORY_FAILURE,
        error: error,
    }
}
