import React from 'react'
import {history} from '../../helpers'
import {loadProjects, addProject} from '../../store/actions/projects.actions'
import {connect} from 'react-redux'
import DataGrid from '../../ui/DataGrid/DataGrid'
import SVG from 'react-inlinesvg'
import ProjectDetailsHeader from '../../components/ProjectDetails/ProjectDetailsHeader/ProjectDetailsHeader'
import {loadprojectTypes, loadprojectStages} from '../../store/actions/types.actions'
import Spinner from '../../components/Spinner/Spinner'
import {sortString} from '../../helpers/sorter/sorter'
import '../../scss/internal/forms.scss'
import {Link} from "react-router-dom";

class Projects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addPanelVisible: false,
        }

        this.showPanel = this.showPanel.bind(this)
    }

    componentDidMount() {
        if (!this.props.projectsLoaded) {
            this.props.loadProjects()
        }
        if (!this.props.projectTypesLoaded) {
            this.props.loadprojectTypes()
        }
        if (!this.props.projectStagesLoaded) {
            this.props.loadprojectStages()
        }
    }

    columns = [
        {
            dataField: 'name',
            text: 'Name',
            editable: false,
        },
        {
            dataField: 'projectType',
            text: 'Type',
            editable: false,
        },
        {
            dataField: 'budget',
            text: 'Budget',
            editable: false,
        },
        {
            dataField: 'projectStage',
            text: 'Stage',
            editable: false,
        },
        {
            dataField: 'archived',
            text: 'archived',
            editable: false,
        },
        {
            dataField: 'add',
            isDummyField: true,
            text: '',
            editable: false,
            formatter: (cellContent, row) => {
                return (
                    <button className="btn btn-circle btn-pink align-right">
                        <Link to={'/projects/' + row.id}>
                            <SVG className="icon-circle-white" src="/assets/icons/pencil.svg" />
                        </Link>
                    </button>
                )
            },
        },
    ]

    edit(row) {
        history.push('/projects/' + row.id)
    }

    addProject = project => {
        this.props.addProject(project)
    }

    showPanel() {
        this.setState({
            addPanelVisible: true,
        })
    }

    sortData() {
        this.props.projects.sort(sortString('name'))
    }

    render() {
        return (
            <>
                {this.sortData()}
                {this.props.projectStagesLoaded && this.props.projectTypesLoaded && (
                    <div className="page-margin my-4">
                        <h1>Projects</h1>
                        <DataGrid
                            columns={this.columns}
                            data={this.props.projects.map(project => ({
                                ...project,
                                projectType: this.props.projectTypes.find(opt => opt.value === project.projectType)?.label,
                                projectStage: this.props.projectStages.find(opt => opt.value === project.projectStage)?.label,
                                archived: project.archived?"Yes":"No",
                            }))}></DataGrid>
                        {this.state.addPanelVisible && (
                            <ProjectDetailsHeader
                                project={{
                                    name: '',
                                    projectType: this.props.projectTypes[0].value,
                                    budget: 0,
                                    projectStage: this.props.projectStages[0].value,
                                    archived: false,
                                }}
                                onSave={this.addProject}></ProjectDetailsHeader>
                        )}
                        {!this.state.addPanelVisible && (
                            <div className="d-flex">
                                <button className="btn btn-pink btn-circle mx-auto mt-4" onClick={this.showPanel}>
                                    <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {!this.props.projectStagesLoaded && !this.props.projectTypesLoaded && <Spinner />}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        projectsLoaded: state.projects.projectsLoaded,
        projectTypesLoaded: state.types.projectTypesLoaded,
        projectTypes: state.types.projectTypes,
        projectStagesLoaded: state.types.projectStagesLoaded,
        projectStages: state.types.projectStages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProjects: () => dispatch(loadProjects()),
        addProject: project => dispatch(addProject(project)),
        loadprojectTypes: () => dispatch(loadprojectTypes()),
        loadprojectStages: () => dispatch(loadprojectStages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
