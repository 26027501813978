import * as actionTypes from '../actions/projects.actions'
import clone from 'ramda/src/clone'
import {mapStrapiCost, mapStrapiProject} from '../../helpers'

const initialState = {
    projectsLoaded: false,
    projectLoaded: true,
    projectAdded: true,
    projects: [],
}

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.projects.projects.map(project => ({
                    ...mapStrapiProject(project),
                    detailsLoaded: false,
                })),
                projectsLoaded: true,
            }
        case actionTypes.LOAD_PROJECT_DETAILS: {
            return {
                ...state,
                projectLoaded: false,
            }
        }
        case actionTypes.LOAD_PROJECT_DETAILS_SUCCESS: {
            if (action.project && action.project[0]) {
                const index = state.projects.findIndex(project => project.id === action.project[0].id)
                let newProjects = clone(state.projects)
                const project = mapStrapiProject(action.project[0])
                if (index !== -1) {
                    newProjects[index] = project
                    newProjects[index].detailsLoaded = true
                }

                return {
                    ...state,
                    projects: newProjects,
                    projectLoaded: true,
                }
            }

            return {
                ...state,
                projectLoaded: true,
            }
        }
        case actionTypes.DELETE_COST_SUCCESS: {
            let newProjects = clone(state.projects)
            const costIndex = newProjects[action.projectIndex].costs.findIndex(cost => cost.id === action.costId)
            newProjects[action.projectIndex].costs.splice(costIndex, 1)

            return {
                ...state,
                projects: newProjects,
            }
        }
        case actionTypes.ADD_COST: {
            return {
                ...state,
                projectAdded: false,
            }
        }
        case actionTypes.ADD_COST_SUCCESS: {
            let newProjects = clone(state.projects)
            newProjects[action.projectIndex].costs.push(mapStrapiCost(action.cost))

            return {
                ...state,
                projects: newProjects,
                projectAdded: true,
            }
        }
        case actionTypes.DELETE_PROJECT_SUCCESS: {
            let newProjects = clone(state.projects)
            newProjects.splice(action.projectIndex, 1)

            return {
                ...state,
                projects: newProjects,
            }
        }
        case actionTypes.ADD_PROJECT: {
            return {
                ...state,
                projectAdded: false,
            }
        }
        case actionTypes.ADD_PROJECT_SUCCESS: {
            let newProjects = clone(state.projects)
            const project = {
                ...mapStrapiProject(action.project),
                edited: false,
            }
            newProjects.push(project)
            return {
                ...state,
                projects: newProjects,
                projectAdded: true,
            }
        }
        case actionTypes.SAVE_PROJECT_CHANGES_SUCCESS: {
            let newProjects = clone(state.projects)
            newProjects.push(action.project)

            return {
                ...state,
                projects: newProjects,
            }
        }
        case actionTypes.ARCHIVE_COST_SUCCESS: {
            let newProjects = clone(state.projects)
            newProjects[action.projectIndex].costs[action.costIndex].archived = true

            return {
                ...state,
                projects: newProjects,
            }
        }
        default:
            return state
    }
}

export default projectsReducer
