import {history, mapAppProject} from '../../helpers'
import {handleError} from '../../helpers'

import axios from 'axios'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_FAILURE = 'LOAD_PROJECTS_FAILURE'

export const LOAD_PROJECT_DETAILS = 'LOAD_PROJECT_DETAILS'
export const LOAD_PROJECT_DETAILS_SUCCESS = 'LOAD_PROJECT_DETAILS_SUCCESS'
export const LOAD_PROJECT_DETAILS_FAILURE = 'LOAD_PROJECT_DETAILS_FAILURE'

export const DELETE_COST = 'DELETE_COST'
export const DELETE_COST_SUCCESS = 'DELETE_COST_SUCCESS'
export const DELETE_COST_FAILURE = 'DELETE_COST_FAILURE'

export const ADD_COST = 'ADD_COST'
export const ADD_COST_SUCCESS = 'ADD_COST_SUCCESS'
export const ADD_COST_FAILURE = 'ADD_COST_FAILURE'

export const ARCHIVE_COST = 'ARCHIVE_COST'
export const ARCHIVE_COST_SUCCESS = 'ARCHIVE_COST_SUCCESS'
export const ARCHIVE_COST_FAILURE = 'ARCHIVE_COST_FAILURE'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'

export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE'

export const SAVE_PROJECT_CHANGES = 'SAVE_PROJECT_CHANGES'
export const SAVE_PROJECT_CHANGES_SUCCESS = 'SAVE_PROJECT_CHANGES_SUCCESS'
export const SAVE_PROJECT_CHANGES_FAILURE = 'SAVE_PROJECT_CHANGES_FAILURE'

export const loadProjects = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const body = {
            query: `
                    query {
                    projects (sort: "name:asc", where: {company: "${store.getState().auth.user.companyId}"}){ 
                    id
                    name
                    archived
                    budget
                    projectType {id}
                    projectStage {id}
                    }
                }
            `,
        }
        dispatch({type: LOAD_PROJECTS})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadProjectsSuccess(response.data.data))
            })
            .catch(error => dispatch(loadProjectsFailure(error.response.data.data)))
    }
}

export const loadProjectsSuccess = projects => {
    return {
        type: LOAD_PROJECTS_SUCCESS,
        projects: projects,
    }
}

export const loadProjectsFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECTS_FAILURE,
        error: error,
    }
}

export const loadProjectDetails = id => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        query: `
            query {
                projects(where: { _id: "${id}" } sort: "name:asc") {
                    id
                    name
                    archived
                    projectStage {
                    id
                    }
                    projectType {
                    id
                    }
                    budget
                    costs {
                    id
                    user {
                        id
                    }
                    employeeLevel {
                        id
                    }
                    internal
                    external
                    archived
                    }
                }
            }
            `,
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_DETAILS})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(loadProjectDetailsSuccess(response.data.data.projects))
            })
            .catch(error => dispatch(loadProjectDetailsFailure(error.response.data.data)))
    }
}

export const loadProjectDetailsSuccess = project => {
    return {
        type: LOAD_PROJECT_DETAILS_SUCCESS,
        project: project,
    }
}

export const loadProjectDetailsFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_DETAILS_FAILURE,
        error: error,
    }
}

export const deleteCost = (projectId, costId) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_COST})
        axios
            .delete(`${baseUrl}/costs/${costId}`, config)
            .then(() => {
                dispatch(deleteCostSuccess(projectId, costId))
            })
            .catch(error => dispatch(deleteCostFailure(error.response.data.data)))
    }
}

export const deleteCostSuccess = (projectIndex, costId) => {
    return {
        type: DELETE_COST_SUCCESS,
        projectIndex,
        costId,
    }
}

export const deleteCostFailure = error => {
    handleError(error)
    return {
        type: DELETE_COST_FAILURE,
        error,
    }
}

export const addCost = (projectIndex, projectId, cost) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        query: `mutation {
    createCost (input:
    {
    data:
    {
            internal: ${cost.internalRate},
            external: ${cost.externalRate},
            project: "${projectId}",
            employeeLevel: "${cost.position}",
            user: "${cost.employee}",
            archived: false
    }
    })
    {
    cost{
        id,
        internal,
        external,
        employeeLevel { id }
        user { id }
        archived
    }
    }
    }`,
    }

    return dispatch => {
        dispatch({type: ADD_COST})
        axios
            .post(`${baseUrl}/graphql`, body, config)
            .then(response => {
                dispatch(addCostSuccess(projectIndex, response.data.data.createCost.cost))
            })
            .catch(error => dispatch(addCostFailure(error.response.data.data)))
    }
}

export const addCostSuccess = (projectIndex, cost) => {
    return {
        type: ADD_COST_SUCCESS,
        projectIndex,
        cost,
    }
}

export const addCostFailure = error => {
    handleError(error)
    return {
        type: ADD_COST_FAILURE,
        error: error,
    }
}

export const archiveCost = (projectIndex, costIndex, costId) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const updateBody = {
        query: `mutation {
    updateCost (input:
    {
    where: {
        id: "${costId}"
    }
    data:
    {
            archived: true
    }
    })
    {
    cost{
        id
    }
    }
    }`,
    }

    return dispatch => {
        dispatch({type: ARCHIVE_COST})
        axios
            .post(`${baseUrl}/graphql`, updateBody, config)
            .then(() => {
                dispatch(archiveduccess(projectIndex, costIndex))
            })
            .catch(error => dispatch(archiveFailure(error.response.data.data)))
    }
}

export const archiveduccess = (projectIndex, costIndex) => {
    return {
        type: ARCHIVE_COST_SUCCESS,
        projectIndex,
        costIndex,
    }
}

export const archiveFailure = error => {
    handleError(error)
    return {
        type: ARCHIVE_COST_FAILURE,
        error: error,
    }
}

export const deleteProject = (projectIndex, projectId) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_PROJECT})
        axios
            .delete(`${baseUrl}/projects/${projectId}`, config)
            .then(response => {
                dispatch(deleteProjectSuccess(projectIndex, response.data))
            })
            .catch(error => dispatch(deleteProjectFailure(error.response.data.data)))
    }
}

export const deleteProjectSuccess = projectIndex => {
    history.replace('/projects')
    return {
        type: DELETE_PROJECT_SUCCESS,
        projectIndex,
    }
}

export const deleteProjectFailure = error => {
    handleError(error)
    return {
        type: DELETE_PROJECT_FAILURE,
        error: error,
    }
}

export const addProject = project => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    const body = {
        name: project.name,
        company: store.getState().auth.user.companyId,
        archived: false,
        budget: project.budget,
        projectStage: project.projectStage,
        projectType: project.projectType,
        costs: [],
    }
    return dispatch => {
        dispatch({type: ADD_PROJECT})
        axios
            .post(`${baseUrl}/projects`, body, config)
            .then(response => {
                dispatch(addProjectSuccess(response.data))
            })
            .catch(error => dispatch(addProjectFailure(error.response.data.data)))
    }
}

export const addProjectSuccess = project => {
    return {
        type: ADD_PROJECT_SUCCESS,
        project,
    }
}

export const addProjectFailure = error => {
    handleError(error)
    return {
        type: ADD_PROJECT_FAILURE,
        error: error,
    }
}

export const saveProjectChanges = (project, projectId) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: SAVE_PROJECT_CHANGES})
        axios
            .put(`${baseUrl}/projects/${projectId}`, mapAppProject(project), config)
            .then(response => {
                dispatch(saveProjectChangesSuccess(response.data))
            })
            .catch(error => dispatch(saveProjectChangesFailure(error.response.data.data)))
    }
}

export const saveProjectChangesSuccess = project => {
    return {
        type: SAVE_PROJECT_CHANGES_SUCCESS,
        project,
    }
}

export const saveProjectChangesFailure = error => {
    handleError(error)
    return {
        type: SAVE_PROJECT_CHANGES_FAILURE,
        error: error,
    }
}
