import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import SVG from 'react-inlinesvg'
import styles from './newHour.module.scss'
import '../../../scss/internal/forms.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import {connect} from 'react-redux'
import {hideModal, showModal} from '../../../store/actions/modals.actions'

class NewHour extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            project: this.props.lastProject
                ? this.props.projects.find(project => project.label === this.props.lastProject)
                    ? this.props.projects.find(project => project.label === this.props.lastProject).value
                    : ''
                : '',
            task: '',
            description: '',
            value: '',
            date: this.props.date ? this.props.date : this.props.month.toDate(),
            loading: false,
        }
    }

    handleProjectChange = value => {
        this.setState({
            ...this.state,
            project: value,
        })
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleNumberChange = e => {
        let {name, value} = e.target
        // eslint-disable-next-line no-useless-escape
        const regex = /^[0-9]*(\.|\,)?[0-9]*$/
        if (value === '') {
            this.setState({[name]: ''})
        } else if (regex.test(value) && parseFloat(value) < 12) {
            this.setState({[name]: parseFloat(value)})
        } else if (regex.test(value) && parseFloat(value) > 12) {
            this.setState({[name]: 12})
        }
    }

    handleBlur = e => {
        let {name, value} = e.target
        value = parseFloat(value)
        value = value ? Math.round(value / 0.25) * 0.25 : 0.5
        this.setState({[name]: value})
    }

    handleDateChange = value => {
        this.setState({
            ...this.state,
            date: value,
        })
    }

    add = () => {
        if (this.state.description !== '' && this.state.value !== '') {
            this.props.addHour(this.state)
            this.startSpinning()
            this.setState({
                description: '',
                value: '',
                task: '',
            })
        }
    }

    keyPressed = event => {
        if (event.key === 'Enter') {
            this.add()
        }
    }

    startSpinning() {
        this.setState({loading: true})
    }
    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    render() {
        const selectedProject = this.props.lastProject
            ? this.props.projects.find(project => project.label === this.props.lastProject)
            : {value: '', label: ''}

        return (
            <div>
                <h3 className={styles.title}>New entry:</h3>
                <div className={styles.hourForm + ' d-flex a-end my-4'}>
                    <div className="mr-4 f-shrink">
                        <p className="caption">Project</p>
                        <CustomSelect
                            className="formControl"
                            selected={selectedProject ? selectedProject : {value: '', label: ''}}
                            options={this.props.projects}
                            change={this.handleProjectChange}></CustomSelect>
                    </div>
                    {this.props.dateEditable && (
                        <div className="mr-4">
                            <p className="caption">Date</p>
                            <CustomDatePicker
                                value={this.state.date}
                                minDate={this.props.month.toDate()}
                                maxDate={this.props.month
                                    .clone()
                                    .add(1, 'M')
                                    .toDate()}
                                onUpdate={this.handleDateChange}
                            />
                        </div>
                    )}
                    {!this.props.dateEditable && (
                        <div className="d-flex a-center mr-4">
                            <p>
                                {('0' + this.state.date.getDate()).slice(-2)}/
                                {('0' + (this.state.date.getUTCMonth() + 1)).slice(-2)}/
                                {this.state.date.getUTCFullYear()}
                            </p>
                        </div>
                    )}
                    <div className="mr-4 max-w-small">
                        <p className="caption">Hours</p>
                        <input
                            type="number"
                            min="0"
                            className="formControl w-100"
                            name="value"
                            value={this.state.value}
                            onChange={this.handleNumberChange}
                            onBlur={this.handleBlur}
                        />
                    </div>
                    <div className="mr-4 max-w-medium">
                        <p className="caption">Task</p>
                        <input
                            type="text"
                            onKeyPress={this.keyPressed}
                            className="formControl w-100"
                            name="task"
                            value={this.state.task}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="mr-4 f-grow">
                        <p className="caption">Description</p>
                        <input
                            type="text"
                            onKeyPress={this.keyPressed}
                            className="formControl w-100"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                        />
                    </div>
                    <button
                        className="btn btn-circle btn-pink"
                        onClick={this.add}
                        disabled={this.state.description === '' || this.state.value === ''}>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
                {this.props.hoursChanged === false ? this.showModal() : this.closeModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hoursChanged: state.timesheet.hoursChanged,
})
const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHour)
