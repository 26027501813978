import axios from 'axios'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_EMPLOYEES_POSITIONS = 'LOAD_EMPLOYEES_POSITIONS'
export const LOAD_EMPLOYEES_POSITIONS_SUCCESS = 'LOAD_EMPLOYEES_POSITIONS_SUCCESS'
export const LOAD_EMPLOYEES_POSITIONS_FAILURE = 'LOAD_EMPLOYEES_POSITIONS_FAILURE'

export const LOAD_PROJECT_TYPES = 'LOAD_PROJECT_TYPES'
export const LOAD_PROJECT_TYPES_SUCCESS = 'LOAD_PROJECT_TYPES_SUCCESS'
export const LOAD_PROJECT_TYPES_FAILURE = 'LOAD_PROJECT_TYPES_FAILURE'

export const LOAD_PROJECT_STAGES = 'LOAD_PROJECT_STAGES'
export const LOAD_PROJECT_STAGES_SUCCESS = 'LOAD_PROJECT_STAGES_SUCCESS'
export const LOAD_PROJECT_STAGES_FAILURE = 'LOAD_PROJECT_STAGES_FAILURE'

export const LOAD_CONTRACT_TYPES = 'LOAD_CONTRACT_TYPES'
export const LOAD_CONTRACT_TYPES_SUCCESS = 'LOAD_CONTRACT_TYPES_SUCCESS'
export const LOAD_CONTRACT_TYPES_FAILURE = 'LOAD_CONTRACT_TYPES_FAILURE'

export const LOAD_PROJECT_LIST = 'LOAD_PROJECT_LIST'
export const LOAD_PROJECT_LIST_SUCCESS = 'LOAD_PROJECT_LIST_SUCCESS'
export const LOAD_PROJECT_LIST_FAILURE = 'LOAD_PROJECT_LIST_FAILURE'

export const loadPositions = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_EMPLOYEES_POSITIONS})
        axios
            .get(`${baseUrl}/employee-levels`, config)
            .then(response => {
                dispatch(loadPositionsSuccess(response.data))
            })
            .catch(error => dispatch(loadPositionsFailure(error.response.data.data)))
    }
}

export const loadPositionsSuccess = positions => {
    return {
        type: LOAD_EMPLOYEES_POSITIONS_SUCCESS,
        positions,
    }
}

export const loadPositionsFailure = error => {
    handleError(error)
    return {
        type: LOAD_EMPLOYEES_POSITIONS_FAILURE,
        error: error,
    }
}

export const loadprojectTypes = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_TYPES})
        axios
            .get(`${baseUrl}/project-types`, config)
            .then(response => {
                dispatch(loadprojectTypesSuccess(response.data))
            })
            .catch(error => dispatch(loadprojectTypesFailure(error.response.data.data)))
    }
}

export const loadprojectTypesSuccess = types => {
    return {
        type: LOAD_PROJECT_TYPES_SUCCESS,
        types,
    }
}

export const loadprojectTypesFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_TYPES_FAILURE,
        error: error,
    }
}

export const loadprojectStages = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_STAGES})
        axios
            .get(`${baseUrl}/project-stages`, config)
            .then(response => {
                dispatch(loadprojectStagesSuccess(response.data))
            })
            .catch(error => dispatch(loadprojectTypesFailure(error.response.data.data)))
    }
}

export const loadprojectStagesSuccess = stages => {
    return {
        type: LOAD_PROJECT_STAGES_SUCCESS,
        stages,
    }
}

export const loadprojectStagesFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_STAGES_FAILURE,
        error: error,
    }
}

export const loadContractTypes = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_CONTRACT_TYPES})
        axios
            .get(`${baseUrl}/employee-contract-types`, config)
            .then(response => {
                dispatch(loadContractTypesSuccess(response.data))
            })
            .catch(error => dispatch(loadprojectTypesFailure(error.response.data.data)))
    }
}

export const loadContractTypesSuccess = contracttypes => {
    return {
        type: LOAD_CONTRACT_TYPES_SUCCESS,
        contracttypes,
    }
}

export const loadContractTypesFailure = error => {
    handleError(error)
    return {
        type: LOAD_CONTRACT_TYPES_FAILURE,
        error: error,
    }
}

export const loadProjectList = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_LIST})
        axios
            .get(`${baseUrl}/projects/user/${store.getState().auth.user.id}?archived=false&company=${store.getState().auth.user.companyId}`, config)
            .then(response => {
                dispatch(loadProjectListSuccess(response.data))
            })
            .catch(error => dispatch(loadProjectListFailure(error.response.data.data)))
    }
}

export const loadProjectListSuccess = projects => {
    return {
        type: LOAD_PROJECT_LIST_SUCCESS,
        projects,
    }
}

export const loadProjectListFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_LIST_FAILURE,
        error: error,
    }
}
