import React, {Component} from 'react'
import Tabs from '../../ui/Tabs/Tabs'
import MonthGrid from '../../components/Timesheet/monthGrid/MonthGrid'
import {loadHours} from '../../store/actions/timesheet.actions'
import WeekGrid from '../../components/Timesheet/weekGrid/WeekGrid'
import {connect} from 'react-redux'
import {loadProjectList} from '../../store/actions/types.actions'
import moment from 'moment'
import {showModal} from '../../store/actions/modals.actions'
import InfoPopover from "../../ui/InfoPopup/infoPopover";

import '../../scss/internal/forms.scss'

class Timesheet extends Component {
    componentDidMount() {
        this.props.loadHours(moment().format('M'))
        this.props.loadProjectList()
    }

    render() {
        return (
            <div className="page-margin my-4">
                <h1>
                    Timesheet
                    <InfoPopover title={"Placeholder for real instructions"}
                                 message={"Here is simple placeholder" +
                                     "We will add this later" +
                                     "Here should be instructions" +
                                     "But for now is only placeholder"}
                                 placement={"left"}/>
                </h1>
                <Tabs>
                    Month
                    <div>
                        <MonthGrid
                            month={moment().startOf('month')}
                            hours={this.props.hours.filter(hour =>
                                moment(hour.date).isSame(moment(), 'month'),
                            )}></MonthGrid>
                        <div className="mt-6"></div>
                        {moment().isBefore(
                            moment()
                                .startOf('month')
                                .add(5, 'days'),
                        ) && (
                            <MonthGrid
                                month={moment()
                                    .subtract(1, 'M')
                                    .startOf('month')}
                                hours={this.props.hours.filter(hour =>
                                    moment(hour.date).isSame(moment().subtract(1, 'M'), 'month'),
                                )}></MonthGrid>
                        )}
                    </div>
                    Week
                    <WeekGrid></WeekGrid>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hours: state.timesheet.hours,
    hoursLoaded: state.timesheet.hoursLoaded,
    projects: state.types.projects,
    projectsLoaded: state.types.projectsLoaded,
    hoursChanged: state.timesheet.hoursChanged,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: (month, year) => dispatch(loadHours(month, year)),
        loadProjectList: () => dispatch(loadProjectList()),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet)
