import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import styles from './projectDetailsHeader.module.scss'
import SVG from 'react-inlinesvg'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {loadprojectTypes, loadprojectStages} from '../../../store/actions/types.actions'
import {hideModal, showModal} from '../../../store/actions/modals.actions'

class ProjectDetailsHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: {...this.props.project},
            changed: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleArchive= this.handleArchive.bind(this)
        this.handleStageChange = this.handleStageChange.bind(this)
        this.handleTypeChange = this.handleTypeChange.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        if (!this.props.projectTypesLoaded) {
            this.props.loadprojectTypes()
        }
        if (!this.props.projectStagesLoaded) {
            this.props.loadprojectStages()
        }
    }

    handleChange(e) {
        const {name, value} = e.target
        this.setState({...this.state, project: {...this.state.project, [name]: value}, changed: true})
    }

    handleStageChange(value) {
        this.setState({
            ...this.state,
            project: {...this.state.project, projectStage: value},
            changed: true,
        })
    }
    handleArchive(value) {
        this.setState({
            ...this.state,
            project: {...this.state.project, archived: value},
            changed: true,
        })
    }
    handleTypeChange(value) {
        this.setState({
            ...this.state,
            project: {...this.state.project, projectType: value},
            changed: true,
        })
    }

    save() {
        this.props.onSave({...this.state.project})
        this.setState({
            changed: false,
        })
    }

    showButton = () => {
        return Object.values(this.state.project).filter(prop => prop === '').length === 0 && this.state.changed
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }
    
    render() {
        const archivedOptions = [{ value: true, label: 'Yes' },{ value: false, label: 'No' }]
        return (
            <div>
                {this.props.projectStagesLoaded && this.props.projectTypesLoaded && (
                    <div className={styles.detailsHeader}>
                        <div className={styles.controlContainer}>
                            <p className={styles.caption + ' caption'}>Name:</p>
                            <input
                                type="text"
                                className={'formControl ' + styles.formControl}
                                name="name"
                                value={this.state.project.name}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Archived:</p>
                            <CustomSelect
                                    selected={archivedOptions.find(option => option.value === this.state.project.archived)}
                                    options={archivedOptions}
                                    change={this.handleArchive}
                                    >
                            </CustomSelect>
                        </div>    
                        <div className={styles.controlContainer}>
                            <p className={styles.caption + ' caption'}>Type:</p>
                            <CustomSelect
                                selected={this.props.projectTypes.find(
                                    opt => opt.value === this.state.project.projectType,
                                )}
                                options={this.props.projectTypes}
                                change={this.handleTypeChange}></CustomSelect>
                        </div>
                        <div className={styles.controlContainer}>
                            <p className={styles.caption + ' caption'}>Stage:</p>
                            <CustomSelect
                                selected={this.props.projectStages.find(
                                    opt => opt.value === this.state.project.projectStage,
                                )}
                                options={this.props.projectStages}
                                change={this.handleStageChange}></CustomSelect>
                        </div>
                        <div className={styles.controlContainer}>
                            <p className={styles.caption + ' caption'}>Budget:</p>
                            <input
                                type="number"
                                min="0"
                                className={'formControl ' + styles.formControl}
                                name="budget"
                                value={this.state.project.budget}
                                onChange={this.handleChange}
                            />
                        </div>
                        {this.showButton() ? (
                            <button disabled={false} className="btn btn-circle btn-pink" onClick={this.save}>
                                <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                            </button>
                        ) : (
                            <button disabled={true} className="btn btn-circle btn-pink" onClick={this.save}>
                                <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                            </button>
                        )}
                    </div>
                )}
                {this.props.projectAdded === false ? this.showModal() : this.closeModal()}
            </div>
        )
    }
}

ProjectDetailsHeader.propTypes = {
    project: PropTypes.shape({
        name: PropTypes.string.isRequired,
        archived: PropTypes.bool.isRequired,
        projectType: PropTypes.string.isRequired,
        budget: PropTypes.number.isRequired,
        projectStage: PropTypes.string.isRequired,
    }),
    projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    projectStages: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    projectStagesLoaded: PropTypes.bool.isRequired,
    projectTypesLoaded: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    loadprojectTypes: PropTypes.func.isRequired,
    loadprojectStages: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        projectTypesLoaded: state.types.projectTypesLoaded,
        projectTypes: state.types.projectTypes,
        projectStagesLoaded: state.types.projectStagesLoaded,
        projectStages: state.types.projectStages,
        projectAdded: state.projects.projectAdded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadprojectTypes: () => dispatch(loadprojectTypes()),
        loadprojectStages: () => dispatch(loadprojectStages()),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsHeader)
